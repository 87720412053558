export function resizeAndCompressImage(croppedImageBase64: string, targetSizeInKB: number, cb: (v: any) => void) {



  const img = new Image();
  img.src = croppedImageBase64;

  img.onload = function () {
    const canvas = document.createElement('canvas');
    const ctx: any = canvas.getContext('2d');

    const aspectRatio = img.width / img.height;
    const targetWidth = Math.sqrt(targetSizeInKB * 1024 * aspectRatio);
    const targetHeight = targetWidth / aspectRatio;

    canvas.width = targetWidth;
    canvas.height = targetHeight;

    ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

    const compressedImageData = canvas.toDataURL('image/png', 0.7);

    cb(compressedImageData)
  };
}
