import { configureStore, combineReducers } from '@reduxjs/toolkit';

import { authApi } from 'services/auth/authService'
import { adminApi } from 'services/admin/adminApi'
import { companyApi } from 'services/company'
import { dashboardApi } from 'services/dashboard/dashboard'
import { userApi } from 'services/user/user'

import signInReducer from './reducers/auth.slice';
import userDataReducer from './reducers/user.slice';
import companyDataReducer from './reducers/companyData';
import dashboardDataReducer from './reducers/dashboardInfo';
import passwordRecoveryReducer from './reducers/recovery.slice';

const combinedReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  signIn: signInReducer,
  userData: userDataReducer,
  companyData: companyDataReducer,
  dashboardData: dashboardDataReducer,
  passwordRecovery: passwordRecoveryReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET') {
    state = {};
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      adminApi.middleware,
      companyApi.middleware,
      dashboardApi.middleware,
      userApi.middleware,
    ),
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;