import React, { useState, useEffect, useRef } from "react";

import WidgetList from './WidgetList'

const useWidgetList = () => {
  const [showBlock, setShowBlock] = useState(false);
  const blockRef = useRef(null);

  const openWidgetsList = () => {
    setShowBlock(true)
  };


  const handleClickOutside = (event: any) => {
    //@ts-ignore
    if (blockRef.current && !blockRef.current?.contains(event.target)) {
      setShowBlock(false);
    }
  };

  useEffect(() => {
    if (showBlock) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showBlock]);

  return {
    openWidgetsList,
    el: (
      <WidgetList
        showBlock={showBlock}
        blockRef={blockRef}
        onClose={() => setShowBlock(false)}
      />
    )
  }
}

export default useWidgetList