import { createSlice } from '@reduxjs/toolkit';

import { saveUserTokensInLocalStorage, clearTokens } from 'helpers'

const userDataSlice = createSlice({
  name: 'userData',
  initialState: {
    data: {},
    isLoading: true,
    error: null,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    getUserDataSuccess(state, action) {
      if (action?.payload?.refresh_token) {
        saveUserTokensInLocalStorage(action.payload)
      }
      state.data = action.payload;
      state.isLoading = false
      state.isSuccess = true
      state.isError = false
    },
    getUserDataFailure(state, action) {
      clearTokens()

      window.location.href = '/auth/sign-in'
      state.error = action.payload;
      state.isLoading = false
      state.isSuccess = false
      state.isError = true
    },
  },
});

export const { getUserDataSuccess, getUserDataFailure } = userDataSlice.actions;

export const selectUserData = (state: any) => state.userData;


export default userDataSlice.reducer;