import { FormattedMessage } from "react-intl";

import Button from 'components/UI/Button'
import EmptyState from 'components/common/EmptyState'
import LayoutContent from 'components/common/LayoutContent'
import AdminPageSubheader from 'components/common/AdminPageSubheader'
import { useManageUserDialog } from "components/common/ManageUserDialog";
import UsersTable from "components/common/UsersTable";
import { ReactIf } from 'components/common/ReactIf'
import Search from 'components/common/Search'
import { useIntlHelper } from 'hooks'
import { plus, companies as emptyCompaniesLogo } from 'assets/icons'
import { User } from 'types/user'

import usePageLogic from './usePageLogic'

import './styles.scss'

const roles = [
  {
    value: 'super_admin',
    label: 'Super Admin'
  }
]

const AdminManagement = () => {
  const { getIntlMessage } = useIntlHelper()
  const {
    filters,
    refetch,
    isListEmpty,
    setFilters,
    userList,
    pages,
  } = usePageLogic()
  const manageUserDialog = useManageUserDialog({
    refreshAfterAction: refetch,
    title: 'newAdmin',
    editTitle: 'editAdmin',
    roles,
    type: 'admin'
  })

  return (
    <LayoutContent>
      <section className='admin-management'>
        <AdminPageSubheader
          title={getIntlMessage('adminManagement')}
        />

        <div className="page-container">
          <div className="admin-users-content">
            <ReactIf value={!isListEmpty}>
              <div className='search-wrapper'>
                <Search
                  onChange={(value: string) => {
                    setFilters({
                      ...filters,
                      pageIndex: 1,
                      search: value,
                    })
                  }}
                />

                <Button
                  startIcon={<img src={plus} alt="add icon" />}
                  onClick={() => manageUserDialog.setOpen(true, {})}
                >
                  <FormattedMessage id="addNewAdmin" />
                </Button>
              </div>
            </ReactIf>

            {
              isListEmpty ? (
                <EmptyState
                  emptyStateLogo={emptyCompaniesLogo}
                  title="Users will be shown here."
                  btnTitle="addNewAdmin"
                  createAction={() => manageUserDialog.setOpen(true, {})}
                />
              ) : (
                <UsersTable
                  data={userList}
                  pages={pages || 0}
                  setFilters={setFilters}
                  filters={filters}
                  refetch={refetch}
                  openEditDialog={(userData: User) => manageUserDialog.setOpen(true, userData)}
                  type="admin"
                />
              )
            }
          </div>
        </div>
      </section>
      {manageUserDialog.el}
    </LayoutContent>
  )
}

export default AdminManagement