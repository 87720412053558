import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logoWhite } from 'assets/icons';
import { getValueFromLS } from 'helpers'
import { routes, tokens } from 'invariants'

import './styles.scss'

type AuthModuleProps = {
  children: React.ReactNode
}

const AuthModule = ({ children }: AuthModuleProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const token = getValueFromLS(tokens.accessToken)
    if (token) {
      navigate(routes.adminCompanies)
    }
  }, [navigate])

  useEffect(() => {
    dispatch({ type: 'RESET' });
  }, [dispatch])

  return (
    <div className="auth-container">
      <div className='white-block'>
        <img className="auth-logo" src={logoWhite} alt="logo-light" />
        {children}
      </div>
    </div>

  )
}

export default AuthModule