import { createSlice } from '@reduxjs/toolkit';

const recoverySlice = createSlice({
  name: 'passwordRecovery',
  initialState: {
    data: null,
    error: null,
  },
  reducers: {
    onPasswordRecoveryInSuccess(state, action) {
      state.data = action.payload;
      state.error = null;
    },
    onPasswordRecoveryFailure(state, action) {
      state.error = action.payload;
    },
  },
});

export const { onPasswordRecoveryInSuccess, onPasswordRecoveryFailure } = recoverySlice.actions;

export const selectSignInData = (state: any) => state.passwordRecovery;

export default recoverySlice.reducer;