import { createSlice } from '@reduxjs/toolkit';

const companyDataSlice = createSlice({
  name: 'companyData',
  initialState: {
    data: {},
    isLoading: true,
    error: null,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    getCompanyDataSuccess(state, action) {
      state.data = action.payload;
      state.isLoading = false
      state.isSuccess = true
    },
    getCompanyDataFailure(state, action) {
      state.error = action.payload;
      state.isLoading = false
      state.isError = true
    },
  },
});

export const { getCompanyDataSuccess, getCompanyDataFailure } = companyDataSlice.actions;

export const selectCompanyData = (state: any) => state.companyData;

export default companyDataSlice.reducer;