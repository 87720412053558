import { NavLink } from 'react-router-dom';

import './styles.scss'

type NavigationTabsProps = {
  navigationList: Array<{
    path: string,
    label: string
  }>
}

const NavigationTabs = ({
  navigationList
}: NavigationTabsProps) => {
  return (
    <nav className='navigation-tabs'>
      <ul className='nav-list'>
        {
          navigationList.map(({ path, label }) => (
            <li className='nav-item' key={label}>
              <NavLink
                to={path}
                className={({ isActive }) =>
                  "item-link" + (isActive ? " iaActive" : "")
                }
              >
                {label}
              </NavLink>
            </li>
          ))
        }
      </ul>
    </nav>
  )
}

export default NavigationTabs