import { useState, useContext } from "react"
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import useIntlHelper from 'hooks/useIntlHelper'
import { toastDefaultSettings } from 'invariants'
import { useManageExternalLinkMutation, useGetDashboardInfoMutation } from 'services/dashboard'
import { IFormikValues } from 'interfaces'
import { Dashboard } from 'types/dashboard'
import { selectDashboardData } from '@redux/reducers/dashboardInfo'

import Context from '../Context'
import ManageExternalLink from "./ManageExternalLink"

const useManageExternalLink = () => {
  const { data: dashboardInfo = {}, isLoading: isLoadingInfo }: any = useSelector(selectDashboardData);
  const [fetchDashboardInfo]: any = useGetDashboardInfoMutation()

  const [manageExternalLink, { isLoading: isLoadingDialog }] = useManageExternalLinkMutation()

  const { companyId, getDashboards, dashboardId } = useContext(Context)
  const [isOpen, setOpen] = useState(false)
  const [isLinkValid, setLinkValid] = useState(true)
  const [errors, setErrors] = useState<any>({})

  const [isEditMode, setEditMode] = useState(false)
  const { getIntlMessage } = useIntlHelper()

  const handleSetOpen = (open: boolean, editMode: boolean) => {
    setEditMode(editMode)
    setOpen(open)
  }

  const handleCreate = (values: IFormikValues) => {
    const isLinkValid = values.link.includes(process.env.REACT_APP_THIRD_PARTY_URL as string)

    if (isLinkValid) {
      manageExternalLink({
        data: {
          name: values.dashboardName || (dashboardInfo as Dashboard)?.name,
          is_hidden: false,
          link: values.link
        },
        companyId,
        dashboardId
      }).then((resp: any) => {
        if (!resp.hasOwnProperty('error')) {
          fetchDashboardInfo({
            companyId, dashboardId
          })
          setErrors({})
          getDashboards()
          toast.success(getIntlMessage(`successfully_message_after_${isEditMode ? 'edit' : 'add_link'}_dashboard`), toastDefaultSettings)
          handleClose()
        } else {
          setErrors(resp?.error?.data?.errors || {})
        }
      })
    } else {
      setLinkValid(false)
    }
  }

  const handleClose = () => {
    setErrors({})
    setOpen(false)
  }

  return {
    el: (
      <ManageExternalLink
        open={isOpen}
        isEditMode={isEditMode}
        onClose={handleClose}
        onApply={handleCreate}
        errors={errors}
        isLinkValid={isLinkValid}
        isLoading={isLoadingInfo || isLoadingDialog}
        dashboardInfo={dashboardInfo as Dashboard}
      />
    ),
    setOpen: handleSetOpen
  }
}

export default useManageExternalLink