import { createApi } from "@reduxjs/toolkit/query/react";

import baseQueryWithReauth from 'services/baseQueryWithReauth'
import { getCompanyDataFailure, getCompanyDataSuccess } from '@redux/reducers/companyData'

export const companyApi = createApi({
  reducerPath: "companyApi",
  keepUnusedDataFor: 1,
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    createUser: build.mutation<any, any>({
      query: (data) => {
        return {
          url: `users`,
          method: "POST",
          body: data,
        };
      },
    }),
    editUser: build.mutation<any, any>({
      query: ({ data, userId }) => {
        return {
          url: `users/${userId}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    getCompanyInfo: build.mutation<any, any>({
      query: ({ companyId }: { companyId: number }) => {
        return {
          url: `companies/${companyId}`,
          method: "GET",
          onSuccess: getCompanyDataSuccess,
          onError: getCompanyDataFailure
        }
      },
    }),
    editCompanyInfo: build.mutation<any, any>({
      query: ({ data, companyId }) => {
        return {
          url: `companies/${companyId}`,
          method: "PUT",
          body: data,
        };
      },
    }),

    changeCompanyStatus: build.mutation<any, any>({
      query: ({ id, status }: any) => {
        return {
          url: `companies/${id}`,
          method: "PATCH",
          body: {
            status
          }
        };
      },
    }),
  }),

});

export const {
  useGetCompanyInfoMutation,
  useEditCompanyInfoMutation,
  useCreateUserMutation,
  useChangeCompanyStatusMutation,
  useEditUserMutation
} = companyApi;
