export const isEmpty = (value: string | Array<any>) => {
  if (typeof value === 'string') {
    return value.trim() === '';
  }

  if (Array.isArray(value)) {
    return !value.length;
  }

  if (typeof value === 'object' && value !== null) {
    return !Object.keys(value).length;
  }

  return false;
}