import { createApi } from "@reduxjs/toolkit/query/react";

import baseQueryWithReauth from 'services/baseQueryWithReauth'
import { getDashboardDataSuccess, getDashboardDataFailure } from '@redux/reducers/dashboardInfo'

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  keepUnusedDataFor: 1,
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    createDashboard: build.mutation<any, any>({
      query: ({ data, companyId }) => {
        return {
          url: `companies/${companyId}/dashboards`,
          method: "POST",
          body: data,
        };
      },
    }),
    manageExternalLink: build.mutation<any, any>({
      query: ({ data, companyId, dashboardId }) => {
        return {
          url: `companies/${companyId}/dashboards/${dashboardId}`,
          method: "PUT",
          body: data,
        };
      },
    }),

    getDashboards: build.mutation<any, any>({
      query: (companyId) => {
        return {
          url: `companies/${companyId}/dashboards`,
          method: "GET",
        };
      },
    }),

    getDashboardToken: build.mutation<any, any>({
      query: (companyId) => {
        return {
          url: `companies/${companyId}/dashboards/auth`,
          method: "GET",
        };
      },
    }),

    changeDashboardOrder: build.mutation<any, any>({
      query: ({ companyId, dashboardId, newPositionIndex },) => {
        return {
          url: `companies/${companyId}/dashboards/${dashboardId}`,
          method: "PATCH",
          body: {
            order_index: newPositionIndex
          },
        };
      },
    }),
    getDashboardInfo: build.mutation<any, any>({
      query: ({ companyId, dashboardId },) => {
        return {
          url: `companies/${companyId}/dashboards/${dashboardId}`,
          method: "GET",
          onSuccess: getDashboardDataSuccess,
          onError: getDashboardDataFailure
        };
      },
    }),
    removeDashboard: build.mutation<any, any>({
      query: ({ companyId, dashboardId }: any) => {
        return {
          url: `companies/${companyId}/dashboards/${dashboardId}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useCreateDashboardMutation,
  useManageExternalLinkMutation,
  useGetDashboardsMutation,
  useGetDashboardInfoMutation,
  useGetDashboardTokenMutation,
  useRemoveDashboardMutation,
  useChangeDashboardOrderMutation
} = dashboardApi;
