import { useState, useMemo } from 'react'

import { useGetAdminsListQuery } from "services/admin";
import { isEmpty } from 'helpers';
import { User, Filtering } from 'types'

export type UsePageLogicOptions = {
  filters: Filtering
  setFilters: (v: Filtering) => void
  isListEmpty: boolean
  pages: number
  refetch: () => Promise<any>
  userList: Array<User>
}

const usePageLogic = (): UsePageLogicOptions => {
  const companyId = 2
  const [filters, setFilters] = useState({
    search: "",
    filterBy: [],
    pageSize: 10,
    pageIndex: 1,
    descending: true,
    sortBy: "created_at",
  })

  const { data = {}, refetch } = useGetAdminsListQuery({
    ...filters,
    companyId,
  })

  const { items: userList = [], pages } = data

  const isListEmpty = useMemo(() => isEmpty(userList) && (isEmpty(filters.search) && isEmpty(filters.filterBy)), [filters, userList])
  return {
    filters,
    //@ts-ignore
    setFilters,
    isListEmpty,
    pages,
    refetch,
    userList
  }
}

export default usePageLogic