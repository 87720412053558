
import React from 'react'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx'

import Button from 'components/UI/Button';
import Dialog, { DialogProps } from 'components/UI/Dialog'
import DialogContent from 'components/UI/DialogContent'
import DialogActions from 'components/UI/DialogActions'
import DialogTitle from 'components/UI/DialogTitle'
import useIntlHelper from "hooks/useIntlHelper";

import './styles.scss'

type ActionDialogProps = DialogProps & {
  onClose: () => void
  onApply: () => void
  ids: {
    [key: string]: string
  }
}

const ActionDialog: React.FC<ActionDialogProps> = (props) => {
  const { onApply, className = '', ids, ...restProps } = props
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { getIntlMessage } = useIntlHelper()

  return (
    <Dialog
      {...restProps}
      fullScreen={fullScreen}
      maxWidth="xs"
      fullWidth
      className={clsx('dialog-container', className)}
      classes={{ paper: 'paper-container' }}
    >
      <DialogTitle  >
        {getIntlMessage(ids.dialogTitle)}
      </DialogTitle>

      <DialogContent  >
        {getIntlMessage(ids.dialogContent)}
      </DialogContent>

      <DialogActions >
        <Button onClick={props.onClose} variant='outlined'>
          {getIntlMessage(ids.actionCancel || "cancel")}
        </Button>

        <Button onClick={onApply}>
          {getIntlMessage(ids.actionApply || "logOut")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ActionDialog