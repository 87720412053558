import SetPassword from "./SetPassword"

const CreatePassword = () => {
  return (
    <SetPassword
      isNewUser={true}
    />
  )
}

export default CreatePassword