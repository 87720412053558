import React, { useState } from 'react';
import clsx from 'clsx'
import {
  Menu,
  MenuItem,
} from '@mui/material';

import Button from 'components/UI/Button';
import { ReactIf } from 'components/common/ReactIf';
import useIntlHelper from "hooks/useIntlHelper";
import { arrowDownGray, arrowDownGreen, arrowDownRed, arrowDownInvited } from 'assets/icons'

import './styles.scss'

const getStatusArrow = (status: string) => {
  switch (status) {
    case 'active':
      return arrowDownGreen;
    case 'inactive':
      return arrowDownRed;
    case 'invited':
      return arrowDownInvited;
    case 'archived':
      return arrowDownGray;

    default:
      return ''
  }
}
type StatusProps = {
  value: string,
  statusList: Array<string>
  onChange: (s: string) => void
  disabled?: boolean
}

const Status: React.FC<StatusProps> = ({ value, statusList, onChange, disabled = false }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { getIntlMessage } = useIntlHelper()
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onStatusChange = (status: string) => {
    onChange(status)
    handleClose()
  }


  return (
    <div className='status-container'>
      <Button
        id="status-btn"
        variant='outlined'
        size='small'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={clsx(
          'status-cell',
          {
            "disabled": disabled,
            "status-active": value === "active",
            "status-inactive": value === "inactive",
            "status-invited": value === "invited",
            "status-disabled": value === "disabled",
            "status-blocked": value === "blocked",
          }
        )}
      >
        {getIntlMessage(`${value}_lb`)}
        <ReactIf value={!disabled}>
          <img src={getStatusArrow(value)} className={clsx({ "is-open": open })} alt='menu' />
        </ReactIf>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {
          statusList.map((status) => (
            <MenuItem key={status} onClick={() => onStatusChange(status)}>{getIntlMessage(status)}</MenuItem>
          ))
        }
      </Menu>
    </div >

  )
}

export default Status