import { FormattedMessage } from "react-intl";

import EmptyState from 'components/common/EmptyState'
import { ReactIf } from 'components/common/ReactIf'
import Search from 'components/common/Search'
import Button from 'components/UI/Button'
import UsersTable from "components/common/UsersTable";
import { useManageUserDialog } from 'components/common/ManageUserDialog'
import { User } from 'types/user'
import { plus, companies as emptyCompaniesLogo } from 'assets/icons'

import usePageLogic from './usePageLogic'

import './styles.scss'

const roles = [
  {
    value: 'company_admin',
    label: 'Company Admin'
  }
]

const UserManagement = () => {
  const {
    filters,
    refetch,
    isListEmpty,
    setFilters,
    userList,
    pages,
  } = usePageLogic()
  const manageUserDialog = useManageUserDialog({ refreshAfterAction: refetch, roles, type: 'user' })

  return (
    <>

      <section className='user-management'>
        <div className="users-content">
          <ReactIf value={!isListEmpty}>
            <div className='search-wrapper'>
              <Search
                onChange={(value: string) => {
                  setFilters({
                    ...filters,
                    pageIndex: 1,
                    search: value,
                  })
                }}
              />

              <Button
                startIcon={<img src={plus} alt="add icon" />}
                onClick={() => manageUserDialog.setOpen(true, {})}
              >
                <FormattedMessage id="newUser" />
              </Button>
            </div>
          </ReactIf>

          {
            isListEmpty ? (
              <EmptyState
                emptyStateLogo={emptyCompaniesLogo}
                title="Users will be shown here."
                btnTitle="newUser"
                createAction={() => manageUserDialog.setOpen(true, {})}
              />
            ) : (
              <UsersTable
                data={userList}
                pages={pages || 0}
                setFilters={setFilters}
                filters={filters}
                refetch={refetch}
                openEditDialog={(userData: User) => manageUserDialog.setOpen(true, userData)}
                type="user"
              />
            )
          }
        </div>
      </section>

      {manageUserDialog.el}
    </>
  )
}

export default UserManagement