import React, { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce';

import OutlinedInput from "components/UI/OutlinedInput";
import { searchGray, clearBlack } from 'assets/icons'

import './styles.scss'

type SearchProps = {
  onChange: (v: string) => void
}

const Search: React.FC<SearchProps> = ({
  onChange
}) => {
  const [value, setValue] = useState('')

  const handleChange = (value: string) => {
    if (onChange) {
      onChange(value)
    }
  }

  const debounced = useDebouncedCallback(handleChange, 1000);

  return (
    <OutlinedInput
      placeholder='search'
      // onChange={(e) => debounced({

      // })}
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const { value = '' } = e.target
        setValue(value);
        debounced(value)
      }}
      startAdornment={<img src={searchGray} alt="search" />}
      endAdornment={Boolean(value) && <img src={clearBlack} className="clear-adorment" alt="clear" onClick={() => {
        handleChange("")
        setValue("")
      }} />}
    />
  )
}

export default Search