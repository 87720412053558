import { useState } from 'react'
import { FieldValues } from 'react-hook-form';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { usePasswordRecoveryMutation } from 'services/auth/authService';
import { routes } from 'invariants';

export type SignInLogicOptions = {
  formHandler: (v: FieldValues) => void;
  isError: boolean
  errors: Array<string>
};

export const usePageLogic = (): SignInLogicOptions => {
  const [errors, setErrors] = useState([])
  const [passwordRecovery, { isError }]: any = usePasswordRecoveryMutation();
  const navigate = useNavigate()

  const formHandler = (values: FieldValues) => {
    passwordRecovery(values)
      .then((response: any) => {
        if (!response.hasOwnProperty('error')) {
          toast.success("Reset password link was sent successfully", {
            closeButton: true,
          })
          navigate(routes.signIn);
        } else {
          setErrors(response.error.data.errors || [])
        }
      })
      .catch((e: any) => {
        console.error('Sign-in failure:', e);
      });
  };

  return {
    formHandler,
    isError,
    errors
  };
};
