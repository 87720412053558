import clsx from 'clsx'

import Dialog, { DialogProps } from 'components/UI/Dialog'
import DialogTitle from 'components/UI/DialogTitle'
import DialogContent from 'components/UI/DialogContent'
import DialogActions from 'components/UI/DialogActions'
import Button from 'components/UI/Button'
import useIntlHelper from "hooks/useIntlHelper";

import './styles.scss'

type ManageCompanyDialogProps = DialogProps & {
  onClose: () => void
  onApply: (v: any) => void
  type: string
}

const DeleteUserDialog = (props: ManageCompanyDialogProps) => {
  const {
    onApply,
    type,
    ...restProps
  } = props
  const { getIntlMessage } = useIntlHelper()


  return (
    <Dialog
      {...restProps}
      fullWidth
      className={clsx('manageCompany-dialog-container')}
      classes={{ paper: 'paper-container' }}
    >
      <DialogTitle>
        {getIntlMessage(`remove_${type}`)}
      </DialogTitle>

      <DialogContent>
        {getIntlMessage(`delete_${type}_confirmation`)}
      </DialogContent>

      <DialogActions  >
        <Button onClick={props.onClose} variant='outlined'>
          {getIntlMessage("cancel")}
        </Button>

        <Button onClick={onApply}>
          {getIntlMessage("remove")}
        </Button>
      </DialogActions>

    </Dialog >
  )
}

export default DeleteUserDialog