// TextWithTooltip.tsx
import React, { useEffect, useRef, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography, { TypographyProps } from '@mui/material/Typography';

import './styles.scss'
interface TextWithTooltipProps extends TypographyProps {
  text: string;
}

const TextWithTooltip: React.FC<TextWithTooltipProps> = ({ text, ...props }) => {
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const textRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (textRef.current && containerRef.current) {
        setIsOverflowing(textRef.current.offsetWidth < textRef.current.scrollWidth);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [text]);

  return (
    <div ref={containerRef} style={{ maxWidth: '100%' }} className='text-with-tooltip'>
      {isOverflowing ? (
        <Tooltip title={text}>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            <Typography ref={textRef} variant="body1" {...props}>
              {text}
            </Typography>
          </div>
        </Tooltip>
      ) : (
        <Typography ref={textRef} variant="body1"  {...props}>
          {text}
        </Typography>
      )}
    </div>
  );
};

export default TextWithTooltip;