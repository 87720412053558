import React from 'react'
import { Tooltip } from '@mui/material'

import { useIntlHelper } from 'hooks'

import './styles.scss'

export type AddOnProps = {
  addOn: Array<string>
}

const AddOn: React.FC<AddOnProps> = ({
  addOn
}) => {
  const { getIntlMessage } = useIntlHelper()

  return (
    <span className='add-on-value'>
      {
        addOn.length ? (
          <>
            <div>{getIntlMessage(addOn[0])}</div>
            {
              addOn.length > 1 && (
                <Tooltip
                  title={addOn.slice(1).map((item: string) => getIntlMessage(item)).join(', ')}
                  placement="top"
                >
                  <div className='circle-counter'>
                    +{addOn.slice(1).length}
                  </div>
                </Tooltip>
              )
            }
          </>
        ) : '-'
      }
    </span>
  )
}

export default AddOn