import { morphism } from 'morphism';

const createUserMorphism = {
  email: "email",
  full_name: "fullName",
  role: "role",
  company_id: "companyId"
};

const manageSuperUserDataMorphism = (values: any) => {
  return morphism(createUserMorphism, values)
}

export default manageSuperUserDataMorphism