import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';

export type DialogProps = MuiDialogProps

const Dialog: React.FC<DialogProps> = ({ children, ...props }) => {
  return (
    <MuiDialog
      {...props}
    >
      {children}
    </MuiDialog>
  )
}

export default Dialog