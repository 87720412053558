import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import { MoreVert } from '@mui/icons-material';

import Pagination from 'components/common/Pagination'
import TableSorting from 'components/common/TableSorting'
import TableFiltering from 'components/common/TableFiltering'
import AvatarList from 'components/common/AvatarList'
import TextWithTooltip from 'components/common/TextWithTooltip';
import AddOn from 'components/common/AddOn'
import EmptyTableState from 'components/common/EmptyTableState'
import Status from 'components/UI/Status'
import { formatDate, isEmpty } from 'helpers'
import { Company, Filtering } from 'types'

import { headerCells, statusFiltering, statuses, prepareRow } from './localInvariants'

import './styles.scss'

type CompaniesTableProps = {
  data: Array<Company>
  pages: number
  filters: Filtering
  setFilters: (v: Filtering) => void
  onChangeStatus: (d: Company, v: string) => void
}

const CompaniesTable: React.FC<CompaniesTableProps> = ({
  data,
  pages,
  filters,
  setFilters,
  onChangeStatus
}) => {
  const navigate = useNavigate()
  const [rowId, setRowId] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const onPaginationChange = (v: any, pageIndex: number) => {
    setFilters({
      ...filters,
      pageIndex
    })
  }

  const handleGoToView = (row: Company) => {
    handleMenuClose()
    navigate(`/admin/company/${row.id}/profile`)
  }

  const handleMenuClick = (event: any, id: number) => {
    setRowId(id)
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='companies-table'>
      {
        data.length ? (
          <>
            <div className='table-wrapper'>
              <Table>
                <TableHead className='table-header'>
                  <TableRow className='table-row'>
                    {
                      headerCells.map(({ id, label, enableSorting, enableFiltering }) => (
                        <TableCell key={id} className='header-cell'>
                          {label}
                          {
                            enableSorting && (
                              <TableSorting
                                id={id}
                                filters={filters}
                                setFilters={setFilters}
                                label={label}
                                defaultSorting={{
                                  descending: true,
                                  sortBy: "created_at",
                                }}
                              />
                            )
                          }
                          {
                            enableFiltering && (
                              <TableFiltering
                                filters={filters}
                                filterList={statusFiltering}
                                setFilters={setFilters}
                                label={label}
                              />
                            )
                          }
                        </TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data.map(row => (
                    <TableRow key={row.id} className='table-row'>
                      <TableCell className='table-cell' sx={{ mixWidth: 200, maxWidth: 600 }}>
                        <div className='company-data-cell' onClick={() => handleGoToView(row)}>
                          <Avatar alt="User Avatar" src={row.logo_cropped_url || ''} sx={{ width: 32, height: 32 }}>
                            {row.name[0]}
                          </Avatar>
                          <div className='company-info'>
                            <TextWithTooltip text={row.name} className="company-name" />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className='table-cell'>
                        <TextWithTooltip text={row.main_contact_full_name || '-'} className='fz-14' />
                      </TableCell>
                      <TableCell className='table-cell'>
                        <AddOn addOn={prepareRow(row)?.module || []} />
                      </TableCell>
                      <TableCell className='table-cell'>
                        <AddOn addOn={prepareRow(row)?.add_on || []} />
                      </TableCell>
                      <TableCell className='table-cell'>
                        {formatDate(row?.start_date || '') || '-'}
                      </TableCell>
                      <TableCell className='table-cell'>
                        <Status value={row.status} statusList={statuses} onChange={(v: string) => onChangeStatus(row, v)} />
                      </TableCell>
                      <TableCell className='table-cell'>
                        {
                          isEmpty(row.associated_users) ? (
                            "Not users yet"
                          ) : (
                            <AvatarList data={row.associated_users} />
                          )
                        }

                      </TableCell>
                      <TableCell className='table-cell'>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(e) => handleMenuClick(e, row.id)}
                        >
                          <MoreVert />
                        </IconButton>
                        <Menu
                          id="companies-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          className='mui-menu'
                        >
                          <MenuItem onClick={() => handleGoToView({ ...row, id: rowId })}>View</MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <Pagination
              page={filters.pageIndex || 1}
              onChange={onPaginationChange}
              count={pages}
              position='right'
            />
          </>
        ) : (
          <EmptyTableState
            title="no_result_found"
            subTitle='cant_found_results'
          />
        )
      }
    </div>
  );
};

export default CompaniesTable;