import React from 'react'

type ReactIfProps = {
  children: React.ReactNode
  value: boolean
}

const ReactIF: React.FC<ReactIfProps> = ({ value, children }) => {
  return (
    <>
      {
        value && (
          children
        )
      }
    </>
  )
}

export default ReactIF 