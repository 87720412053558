
import { morphism } from 'morphism';

const manageCompanyMorphism = {
  name: "name",
  main_contact_full_name: "main_contact_full_name",
  start_date: "start_date",
  add_on: "add_on",
  module: "module",
  status: "status",
  db_name: "db_name",
  db_username: "db_username",
  db_password: "db_password",
  db_host: "db_host",
  db_port: "db_port",
  company_id: "companyId"
};
const createMorphism = {
  ...manageCompanyMorphism,
  logo_original: "logo_original",
  logo_cropped: "logo_cropped",
}

const editMorphism = {
  ...manageCompanyMorphism,
  logo_original_url: "logo_original_url",
  logo_cropped_url: "logo_cropped_url",
  logo_original: "logo_original",
  logo_cropped: "logo_cropped",
}

const createCompanyDataMorphism = (values: any) => {
  return morphism(createMorphism, values)
}
const editCompanyDataMorphism = (values: any) => {
  return morphism(editMorphism, values)
}

export {
  createCompanyDataMorphism,
  editCompanyDataMorphism,
}