import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom'

import { useGetCompanyInfoMutation } from "services/company";
import { selectUserData } from '@redux/reducers/user.slice'
import { isEmpty, isEqual } from 'helpers'
import { userRoles } from 'invariants'
import { useGetDashboardsMutation, useChangeDashboardOrderMutation } from 'services/dashboard'
import { Dashboard, User, Company } from 'types'


type ClientProviderProps = {
  children: React.ReactNode
}

type clientContextType = {
  isEditMode: boolean
  isSuperAdmin: boolean
  userData: User
  companyData: Company
  onOrderChange: (v: string, i: number) => void
  drawerTabs: Dashboard[]
  hiddenTabs: Dashboard[]
  setDrawerTabs: (v: Dashboard[]) => void
  setHiddenTabs: (v: Dashboard[]) => void
  setEditMode: (v: boolean) => void
  companyId: string
  isUserDataLoaded: boolean
  isCompanyDataLoaded: boolean
  dashboardId: string
  getDashboards: (v?: boolean) => void
  //testData below
  widgetsList: any
  onRemoveWidget: any
  onResetToDefault: any
}

const ClientContext = React.createContext<clientContextType>({} as clientContextType)

export const ClientProvider: React.FC<ClientProviderProps> = ({ children }) => {
  const { data = {}, isSuccess: isUserDataLoaded } = useSelector(selectUserData);
  const { dashboardId = '', id: companyId = '' } = useParams()
  const navigate = useNavigate()

  const [fetchDashboards]: any = useGetDashboardsMutation()
  const [changeOrderForDashboard]: any = useChangeDashboardOrderMutation()

  const [fetchData, { isSuccess: isCompanyDataLoaded, data: companyData = {} }] = useGetCompanyInfoMutation()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [drawerTabs, setDrawerTabs] = useState<Dashboard[] | []>([])
  const [hiddenTabs, setHiddenTabs] = useState<Dashboard[] | []>([])
  const [widgetsList, setWidgetsList] = useState<any>([])

  const onOrderChange = useCallback((tabId: string, newPositionIndex: number) => {
    changeOrderForDashboard({
      companyId,
      dashboardId: tabId,
      newPositionIndex,
    })
  }, [companyId, changeOrderForDashboard])

  const getDashboards = useCallback((redirectAnyway: boolean = false) => {
    fetchDashboards(companyId).then(({ data = [] }: { data: Dashboard[] }) => {

      const transformedData = data.map((item: Dashboard) => ({
        ...item,
        id: item.id.toString()
      }))

      setDrawerTabs(transformedData.filter(({ is_hidden }: Dashboard) => !is_hidden))
      setHiddenTabs(transformedData.filter(({ is_hidden }: Dashboard) => is_hidden))


      if ((!dashboardId || redirectAnyway) && !isEmpty(data)) {
        navigate(`/user/company/${companyId}/${data[0]?.id}`)
      }
    })
  },
    // eslint-disable-next-line
    [fetchDashboards, dashboardId, companyId])

  useEffect(() => {
    if (isUserDataLoaded) {
      if (data?.role === 'super_admin') {

        fetchData({ companyId: companyId })
      }
      getDashboards()
    }
  },
    // eslint-disable-next-line
    [companyId, isUserDataLoaded])

  // useEffect(() => { 
  // if (drawerTabs[0]?.id === dashboardId) {
  //   setWidgetsList([])
  // } else {
  //   setWidgetsList([])
  // }
  // }, [dashboardId, drawerTabs])

  const onRemoveWidget = useCallback((index: number) => {
    const newData = widgetsList.filter((item: any, i: number) => i !== index)

    setWidgetsList(newData)
  }, [widgetsList])

  const onResetToDefault = useCallback((index: number) => {
    setWidgetsList([])
  }, [])

  const contextValue = useMemo<clientContextType>(
    () => ({
      companyId,
      dashboardId,
      isEditMode,
      drawerTabs,
      hiddenTabs,
      companyData,
      isUserDataLoaded,
      onOrderChange,
      onResetToDefault,
      setDrawerTabs,
      setHiddenTabs,
      getDashboards,
      isSuperAdmin: isEqual(data?.role, userRoles.super_admin),
      userData: data,
      setEditMode,
      isCompanyDataLoaded,
      widgetsList,
      onRemoveWidget,
    }),
    [
      companyId,
      dashboardId,
      isEditMode,
      setEditMode,
      onResetToDefault,
      data,
      companyData,
      drawerTabs,
      isUserDataLoaded,
      hiddenTabs,
      onOrderChange,
      widgetsList,
      onRemoveWidget,
      setDrawerTabs,
      setHiddenTabs,
      getDashboards,
      isCompanyDataLoaded,
    ]
  )

  return (
    <ClientContext.Provider value={contextValue}>
      {children}
    </ClientContext.Provider>
  )
}

export default ClientContext
