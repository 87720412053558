import { FormattedMessage } from "react-intl";

import Button from 'components/UI/Button'
import { plus } from 'assets/icons'

import './styles.scss'

type EmptyStateProps = {
  emptyStateLogo: any
  title: string
  btnTitle: string
  createAction: () => void
}

const EmptyState: React.FC<EmptyStateProps> = ({
  emptyStateLogo,
  title,
  btnTitle,
  createAction,
}) => {
  return (
    <div className="page-empty-state">
      <img src={emptyStateLogo} alt="empty state" />

      <h2 className="title">{title}</h2>

      <Button
        startIcon={<img src={plus} alt="add icon" />}
        onClick={createAction}
      >
        <FormattedMessage id={btnTitle} />
      </Button>
    </div>
  )
}

export default EmptyState