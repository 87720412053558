import React, { useContext } from 'react'
import Avatar from '@mui/material/Avatar';
import { NavLink } from 'react-router-dom';

import Button from 'components/UI/Button'
import ClientContext from '../Context'

import './styles.scss'

const EditModeSwitcher: React.FC = () => {

  const { isEditMode, setEditMode, companyData, companyId } = useContext(ClientContext)

  return (
    <div className='client-mode-switcher'>
      <div className='title'>
        <span className='title-left'>You are Guest of</span>

        <NavLink to={`/admin/company/${companyId}/profile`} className='title-link'>
          <Avatar alt="Company Avatar" src={companyData?.logo_cropped_url || ''} sx={{ width: 24, height: 24 }}>
            {(companyData?.name || '')[0]}
          </Avatar>
          <span className='comp-name'>
            {companyData?.name}
          </span>
        </NavLink>

        <span className='title-right'> in {isEditMode ? "Edit" : "View"} mode</span>
      </div>

      <div className='actions'>
        {
          isEditMode ? (
            <div className='btns-group'>
              <Button variant='outlined' onClick={() => setEditMode(!isEditMode)}>Exit Edit Mode</Button>
            </div>
          ) : (
            <Button onClick={() => setEditMode(!isEditMode)}>Edit</Button>
          )
        }
      </div>
    </div>
  )
}

export default EditModeSwitcher