import React, { useState, useRef, useEffect, ChangeEvent } from 'react'
import Slider from '@mui/material/Slider';
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";
import { IconButton } from '@mui/material';
import clsx from 'clsx'
import { toast } from "react-toastify";

import Dialog from 'components/UI/Dialog'
import DialogTitle from 'components/UI/DialogTitle'
import DialogContent from 'components/UI/DialogContent'
import DialogActions from 'components/UI/DialogActions'
import Button from 'components/UI/Button'
import useIntlHelper from "hooks/useIntlHelper";

import {
  plusBlack,
  minus,
  addLogo,
  cloudComputing,
} from 'assets/icons'
import { toastDefaultSettings } from 'invariants'
import { LogoData } from 'types';

import getCroppedImg from './getCroppedImg'
import { resizeAndCompressImage } from './resizeAndCompressImage'

import './styles.scss'

type AvatarCropDialogProps = {
  open: boolean
  imgSrc: string
  setOpen: (v: boolean) => void
  saveImage: (v: LogoData) => void
}

interface File extends Blob {
  readonly lastModified: number;
  readonly name: string;
  readonly size: number;
  readonly type: string;
}

const AvatarCropDialog: React.FC<AvatarCropDialogProps> = ({
  open,
  imgSrc,
  setOpen,
  saveImage
}) => {
  const inputRef = useRef(null);
  const { getIntlMessage } = useIntlHelper()

  const [image, setImage] = useState("");
  const [previewSrc, setPreviewSrc] = useState<string>("");
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });

  const [zoom, setZoom] = useState(1);

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    getCroppedImg(image, croppedAreaPixels)
      .then((res: string) => setPreviewSrc(res)
      )
      .catch((error: any) => {
        console.error(error)
      })

  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveAndClose = () => {

    if (image) {
      resizeAndCompressImage(previewSrc, 50, (res: string) => {
        saveImage({
          logo_original: image,
          logo_cropped: res,
        })
      })
    } else {
      saveImage({
        logo_original: null,
        logo_cropped: null,
      })
    }
    handleClose()
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = (e.target.files || [])[0];
    if (!file) {
      return
    }
    handleCheckFile(file, e)
  };

  const handleCheckFile = (file: File, e: any) => {
    const maxSizeInBytes = 5 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      toast.warning('File size exceeds 5MB limit.', toastDefaultSettings)
      return;
    }

    const allowedFormats = ["jpeg", "png", "jpg"];
    const extension = file.name.split(".").pop()?.toLowerCase();
    if (!extension || !allowedFormats.includes(extension)) {
      toast.warning("Invalid file format. Only JPEG, PNG, and JPG formats are allowed.", toastDefaultSettings)
      return;
    }

    const reader: FileReader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as string);
    };
    reader.readAsDataURL(file);
    e.target.value = null
  }

  const handleClear = () => {
    setImage('')
    setPreviewSrc("")
  }

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];

    if (file) {
      handleCheckFile(file, e)
    }
  };

  const handleButtonClick = () => {
    if (inputRef?.current) {
      //@ts-ignore
      inputRef.current.click();
    }
  };

  useEffect(() => {
    setImage(imgSrc)
  }, [imgSrc])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth className='cropper-dialog'>
      <DialogTitle>
        Edit logo
      </DialogTitle>
      <DialogContent>

        <div className='crop-action-container'>
          <img className='crop-logo-preview' src={!!previewSrc.length ? previewSrc : (image || addLogo)} alt="cropped logo" />

          <div className='crop-actions'>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              ref={inputRef}
              style={{ display: 'none' }}
            />
            <Button variant="text" onClick={handleButtonClick}>
              Upload Logo
            </Button>
            <Button variant="text" onClick={handleClear} disabled={!image}>
              Remove Logo
            </Button>
          </div>
        </div>

        {
          image ? (
            <>
              <div className={clsx("crop-container", { "disable-crop": !image })}>
                <Cropper
                  image={image || ""}
                  crop={crop}
                  zoom={zoom}
                  aspect={4 / 4}
                  cropShape="round"
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  showGrid={false}
                />
              </div>
              <div className={clsx("controls", { "disable-crop": !image })}>
                <IconButton onClick={() => setZoom(zoom - 0.1)} >
                  <img src={minus} alt="zoom out" />
                </IconButton>

                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(Number(zoom))}
                  classes={{ root: "slider" }}
                  className="mui-slider"
                />

                <IconButton onClick={() => setZoom(zoom + 0.1)} >
                  <img src={plusBlack} alt="zoom in" />
                </IconButton>
              </div>
            </>
          ) : (
            <>
              <p className='dragArea-title'>Company Logo (optional)</p>
              <div
                id="drop-area"
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={handleButtonClick}
                className={clsx('dragable-area')}
              >
                <img src={cloudComputing} alt="cloudComputing" />
                <p className='description'>
                  <span className='clickToUpload'>Click to upload</span> or drag and drop users list
                  in jpeg, png, jpg format (up to 5MB)
                </p>
              </div>
            </>
          )
        }
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={handleClose}>{getIntlMessage('close')}</Button>
        <Button onClick={handleSaveAndClose}>  {getIntlMessage('saveChanges')}</Button>
      </DialogActions>
    </Dialog >
  )
}

export default AvatarCropDialog