import { ToastOptions } from "react-toastify";

export const MESSAGES = {
  CREDENTIALS_INCORRECT: 'Could not validate credentials'
}
export const routes = {
  adminCompanies: '/admin/companies',
  adminDetailedCompanyData: '/admin/company/:id',
  adminDetailedCompanyProfile: '/admin/company/:id/profile',
  adminDetailedCompanyUsers: '/admin/company/:id/users',
  adminManagement: '/admin/management',
  signIn: '/auth/sign-in',
  passwordRecovery: '/auth/password-recovery',
  setPassword: '/auth/set-password',//set password first time
  newPassword: '/auth/new-password',//set new password after recovery thought email
  clientDashboardContent: '/user/company/:id',
  clientDashboard: '/user/company/:id/:dashboardId',
};

export const toastDefaultSettings: ToastOptions = {
  position: "bottom-right",
  closeButton: true,
  autoClose: 5000
}

export const industryList = [
  {
    value: 'automotive',
    label: 'Automotive'
  },
  {
    value: 'construction',
    label: 'Construction'
  },
  {
    value: 'electrical',
    label: 'Electrical'
  },
  {
    value: 'fasteners',
    label: 'Fasteners'
  },
  {
    value: 'hvac',
    label: 'HVAC'
  },
  {
    value: 'industrial',
    label: 'Industrial'
  },
  {
    value: 'janitorial_and_sanitation',
    label: 'Janitorial and Sanitation'
  },
  {
    value: 'machinery',
    label: 'Machinery'
  },
  {
    value: 'plumbing',
    label: 'Plumbing'
  },
  {
    value: 'safety',
    label: 'Safety'
  },
  {
    value: 'other',
    label: 'Other'
  },
]

export const statusList = {
  active: 'active',
  inactive: 'inactive',
  // archived: 'archived',
}

export const userStatusList = {
  active: 'active',
  inactive: 'inactive',
  invited: 'invited',
}

export const tokens = {
  accessToken: "accessToken",
  refreshToken: "refreshToken",
}

export const userRoles = {
  company_admin: 'company_admin',
  super_admin: 'super_admin',
}