import React from 'react'
import { IconButton } from '@mui/material'

import { backIcon } from 'assets/icons'

import './styles.scss'

export type AdminPageSubHeaderProps = {
  title: string
  withIcon?: boolean
  onClick?: () => void
}

const AdminPageSubHeader: React.FC<AdminPageSubHeaderProps> = ({
  title,
  onClick,
  withIcon = false,
}) => {
  return (
    <div className='subheader-control'>
      {
        withIcon && (
          <IconButton onClick={onClick}>
            <img src={backIcon} alt="go to companies page" />
          </IconButton>
        )
      }


      <h2 className="subheader-title">
        {title}
      </h2>
    </div>
  )
}

export default AdminPageSubHeader