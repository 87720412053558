import React, { useContext, useState } from 'react'
import Popover from '@mui/material/Popover';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';

import Button from 'components/UI/Button'
import { useIntlHelper } from 'hooks'
import { plusBlue, library, externalLink, addCustomWidget } from 'assets/icons'

import Context from '../../../Context'
import useManageExternalLink from '../../../ManageExternalLink'

import './styles.scss'

type AddButtonProps = {
  style: {
    [key: string]: any
  }
}

const AddButton: React.FC<AddButtonProps> = ({ style = {} }) => {
  const manageExternalLink = useManageExternalLink()


  const [anchorEl, setAnchorEl] = useState(null);
  const { getIntlMessage } = useIntlHelper()

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openExternalLink = () => {
    handleClose()
    manageExternalLink.setOpen(true, false)
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { isEditMode } = useContext(Context)
  if (!isEditMode) {
    return <></>
  }

  return (
    <div style={style} className='add-widget-wrapper'>
      <Button
        id="add-widget-button"
        aria-describedby={id}
        variant='text'
        className='add_widget_btn'
        aria-controls={open ? 'options-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <img
          src={plusBlue}
          alt="add new widget"
        />
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuList className="options-menu">
          <MenuItem onClick={handleClose} disabled>
            <img src={library} alt='select widget from library ' /> Select from Library
          </MenuItem>
          <MenuItem onClick={handleClose} disabled>
            <img src={addCustomWidget} alt='select widget from library ' /> Add Custom
          </MenuItem>
          <MenuItem onClick={openExternalLink}>
            <img src={externalLink} alt='select widget from library ' />
            {getIntlMessage('external_dashboard')}
          </MenuItem>
        </MenuList>
      </Popover>

      {manageExternalLink.el}
    </div>
  );
}

export default AddButton