import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import clsx from 'clsx'

import { ReactIf } from 'components/common/ReactIf'
import { selectUserData } from '@redux/reducers/user.slice'
import { isEqual } from 'helpers'
import { userRoles } from 'invariants'

import EditModeSwitcher from './EditModeSwitcher'
import ClientDrawer from './Drawer'
import { ClientProvider } from './Context'

import './styles.scss'

type ClientContentProps = {
  children: React.ReactNode
  autoRedirect?: boolean
}

const ClientContent: React.FC<ClientContentProps> = ({
  autoRedirect = false,
  children
}) => {
  const { data = {} } = useSelector(selectUserData);

  const isSuperAdmin = useMemo(() => isEqual(data?.role, userRoles.super_admin), [data?.role])

  return (
    <>
      <ReactIf value={isSuperAdmin}>
        <EditModeSwitcher />
      </ReactIf>

      <div className={clsx('client-content', {
        'editable-client-content': isSuperAdmin
      })}>
        <ClientDrawer />
        <div className={clsx('dashboard-side', { 'dashboard-side-with-margin': isSuperAdmin })}>
          {children}
        </div>
      </div>
    </>
  )
}

const ProvidedClientContent = ({
  children,
  ...props
}: ClientContentProps) => (
  <ClientProvider>
    <ClientContent {...props}>
      {children}
    </ClientContent>
  </ClientProvider>
)
export default ProvidedClientContent