import clsx from 'clsx'

import Dialog, { DialogProps } from 'components/UI/Dialog'
import DialogTitle from 'components/UI/DialogTitle'
import DialogContent from 'components/UI/DialogContent'
import DialogActions from 'components/UI/DialogActions'
import Button from 'components/UI/Button'
import useIntlHelper from "hooks/useIntlHelper";
import { Company } from 'types/company'

import './styles.scss'

type ManageCompanyDialogProps = DialogProps & {
  onClose: () => void
  onApply: (v: any) => void
  row: Company & {
    newStatus: string
    full_name: string
  }
  type: string
}

const DeleteUserDialog = (props: ManageCompanyDialogProps) => {
  const {
    onApply,
    row,
    type,
    ...restProps
  } = props
  const { getIntlMessage } = useIntlHelper()

  return (
    <Dialog
      {...restProps}
      fullWidth
      className={clsx('manageCompany-dialog-container')}
      classes={{ paper: 'paper-container' }}
    >
      <DialogTitle>
        {getIntlMessage(`${row.newStatus}_${type}_status`)}
      </DialogTitle>

      <DialogContent>
        Are you sure you want to <span className='textTransform-lowerCase'> {getIntlMessage(`${row.newStatus}_action`)}</span> <span className="bold">{row[type === 'company' ? 'name' : 'full_name']}</span>? You can {row.newStatus === 'active' ? 'deactivate' : 'activate'} a {type} later.
      </DialogContent>

      <DialogActions  >
        <Button onClick={props.onClose} variant='outlined'>
          {getIntlMessage("cancel")}
        </Button>

        <Button onClick={onApply}>
          {getIntlMessage(`${row.newStatus}_action`)}
        </Button>
      </DialogActions>

    </Dialog >
  )
}

export default DeleteUserDialog