import { useState, useMemo } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';

import Pagination from 'components/common/Pagination'
import TableSorting from 'components/common/TableSorting'
import TableFiltering from 'components/common/TableFiltering'
import TextWithTooltip from 'components/common/TextWithTooltip';
import EmptyTableState from 'components/common/EmptyTableState'
import { useChangeStatusDialog } from 'components/common/ChangeUserStatusDialog'
import { useDeleteUserDialog } from 'components/common/DeleteUserDialog'
import Status from 'components/UI/Status'
import { userStatusList } from 'invariants'
import { useIntlHelper, useRecentLogin } from 'hooks'
import { User, Filtering } from 'types'

import './styles.scss'

type UsersTableProps = {
  data: User[]
  type: string
  pages: number
  filters: Filtering
  setFilters: (v: Filtering) => void,
  refetch: () => void,
  openEditDialog: (v: User) => void,
}

const statuses = Object.values(userStatusList).filter((status: string) => status !== userStatusList.invited)

const statusFiltering = [
  {
    statusKey: userStatusList.active,
    lbl: "Active"
  },
  {
    statusKey: userStatusList.inactive,
    lbl: "Inactive"
  },
  {
    statusKey: userStatusList.invited,
    lbl: "Invited"
  },
]

const UsersTable = ({
  data,
  pages,
  filters,
  setFilters,
  refetch,
  openEditDialog,
  type,
}: UsersTableProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentUser, setCurrentUser] = useState<User>({} as User);
  const { getIntlMessage } = useIntlHelper()
  const recentLogin = useRecentLogin()

  const deleteUserDialog = useDeleteUserDialog(refetch, type)
  const changeStatusDialog = useChangeStatusDialog(refetch, 'user')

  const onPaginationChange = (a: any, pageIndex: number) => {
    setFilters({
      ...filters,
      pageIndex
    })
  }

  const handleMenuClick = (event: any, row: User) => {
    setCurrentUser(row)
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onStatusChange = (data: User, newStatus: string) => {
    if (data.status !== newStatus) {
      changeStatusDialog.setOpen(true, data, newStatus)
    }
  }

  const headerCells = useMemo(() => ([
    {
      id: "full_name",
      label: `table_${type}_name_cell`,
      enableSorting: true,
    },
    {
      id: "email",
      label: "table_email_cell",
      enableSorting: true,
    },
    {
      id: "role",
      label: "table_role_cell",
    },
    {
      id: "recent_login",
      label: "table_recent_login_cell",
      enableSorting: true,
    },
    {
      id: "status",
      label: "statusField",
      enableFiltering: true,
    },
    {
      id: "actions",
      label: "",
    }
  ]), [type])


  return (
    <div className='user-table'>
      {
        data.length ? (
          <>
            <div className='table-wrapper'>
              <Table>
                <TableHead className='table-header'>
                  <TableRow className='table-row'>
                    {
                      headerCells.map(({ id, label, enableSorting, enableFiltering }) => (
                        <TableCell key={id} className='header-cell'>
                          {getIntlMessage(label)}
                          {
                            enableSorting && (
                              <TableSorting
                                id={id}
                                filters={filters}
                                setFilters={setFilters}
                                label={getIntlMessage(label)}
                                defaultSorting={{
                                  descending: true,
                                  sortBy: "created_at",
                                }}
                              />
                            )
                          }
                          {
                            enableFiltering && (
                              <TableFiltering
                                filters={filters}
                                setFilters={setFilters}
                                filterList={statusFiltering}
                                label={getIntlMessage(label)}
                              />
                            )
                          }
                        </TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data.map((row: User) => (
                    <TableRow key={row.id} className='table-row'>
                      <TableCell className='table-cell'  >
                        <TextWithTooltip text={row.full_name} />
                      </TableCell>
                      <TableCell className='table-cell'>  <TextWithTooltip text={row.email} /> </TableCell>
                      <TableCell className='table-cell'> {getIntlMessage(row.role)} </TableCell>
                      <TableCell className='table-cell'>
                        {recentLogin(row.recent_login as string)}
                      </TableCell>
                      <TableCell className='table-cell'>
                        <Status
                          disabled={row.status === 'invited'}
                          statusList={statuses} value={row.status} onChange={(newStatus) => onStatusChange(row, newStatus)} />
                      </TableCell>
                      <TableCell className='table-cell'>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(e) => handleMenuClick(e, row)}
                        >
                          <MoreVert />
                        </IconButton>
                        <Menu
                          id="companies-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          className='mui-menu'
                        >
                          <MenuItem
                            onClick={() => {
                              openEditDialog(currentUser as User)
                              handleMenuClose()
                            }}>
                            Edit {type}
                          </MenuItem>

                          <MenuItem
                            onClick={() => {
                              deleteUserDialog.setOpen(true, currentUser)
                              handleMenuClose()
                            }}>
                            Remove
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>

            <Pagination
              page={filters.pageIndex}
              onChange={onPaginationChange}
              count={pages}
              position='right'
            />
          </>
        ) : (
          <EmptyTableState
            title="no_result_found"
            subTitle='cant_found_results'
          />
        )
      }
      {deleteUserDialog.el}
      {changeStatusDialog.el}
    </div>
  );
};

export default UsersTable;
