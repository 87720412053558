import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectUserData } from '@redux/reducers/user.slice'
import { getValueFromLS, clearTokens } from 'helpers'
import { routes, tokens, userRoles } from 'invariants'

interface AdminGuardProps {
  children: React.ReactNode;
}

const AdminGuard: React.FC<AdminGuardProps> = ({ children }) => {
  const { data = {}, isError } = useSelector(selectUserData);
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { role, company_id } = data

  useEffect(() => {
    if (role && role !== userRoles.super_admin) {
      navigate(`/user/company/${company_id}`)
    }
  }, [role, navigate, company_id])

  useEffect(() => {
    if (isError) {
      dispatch({ type: 'RESET' });
      clearTokens()
      navigate(`/auth/sign-in`)
    }
  }, [isError, navigate, dispatch])

  useEffect(() => {
    const token = getValueFromLS(tokens.accessToken)
    if (!token) {
      navigate(routes.signIn)
    }
  }, [navigate])

  if (!Object.keys(data).length || role === userRoles.company_admin) {
    return (
      <></>
    )
  }

  return (
    <>
      {children}
    </>
  )
}

export default AdminGuard