import { useState, useMemo, useEffect } from 'react';
import {
  Checkbox,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';

import {
  vector,
  vectorActive
} from 'assets/icons'
import { isEmpty } from 'helpers';
import { Filtering } from 'types'

import './styles.scss'

type TableFiltersProps = {
  label: string
  filters: Filtering
  filterList: Array<{
    statusKey: string,
    lbl: string
  }>
  setFilters: (v: Filtering) => void
}

const TableFilters: React.FC<TableFiltersProps> = ({
  label,
  filters,
  filterList,
  setFilters
}) => {
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [checked, setChecked] = useState<Array<string> | []>([]);

  const handleChange = (statusKey: string) => {
    if ((checked as Array<string>).includes(statusKey)) {
      const newList = checked.filter((key: string) => key !== statusKey)
      setChecked(newList)

      setFilters({
        ...filters,
        filterBy: newList,
      })

      return
    }

    setChecked([...checked, statusKey])
    setFilters({
      ...filters,
      pageIndex: 1,
      filterBy: [...checked, statusKey],
    })

  };

  const handleSortMenuClick = (event: any) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSortAnchorEl(null);
  };

  const filterIcon = useMemo(() => {
    if (isEmpty(checked)) {
      return vector
    }
    return vectorActive
  }, [checked])

  useEffect(() => {
    setChecked(filters.filterBy || [])
  },
    // eslint-disable-next-line
    [])

  return (
    <>
      <IconButton onClick={handleSortMenuClick}>
        <img
          src={filterIcon} alt={`filter by ${label}`} />
      </IconButton>
      <Menu
        anchorEl={sortAnchorEl}
        open={Boolean(sortAnchorEl)}
        onClose={handleClose}
        className="sorting-container"
      >

        <div className='sort-group group-filters'>
          <div className='category-title'>Filtering</div>
          {
            filterList.map(({ statusKey, lbl }) => (
              <MenuItem key={statusKey} onClick={() => handleChange(statusKey)}>
                <Checkbox size="small" checked={(checked as Array<string>).includes(statusKey)} /> {lbl}
              </MenuItem>
            ))
          }

        </div>
      </Menu>
    </>
  )
}

export default TableFilters