import { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import AdminGuard from 'components/common/AdminGuard'
import SignIn from "pages/Auth/SignIn";
import { RestorePassword, CreatePassword } from "pages/Auth/SetPassword";
import PasswordRecovery from "pages/Auth/PasswordRecovery";
import Dashboard from "pages/AdminSide/Companies";
import DetailedCompany, { CompanyProfile, UserManagement } from "pages/AdminSide/DetailedCompany";
import AdminManagement from "pages/AdminSide/AdminManagement";
import { selectUserData } from '@redux/reducers/user.slice'
import {
  ClientContent,
  DashboardContent,
} from 'pages/ClientSide'

import { ToastifyContainer } from 'components/UI/Toastify'
import { useGetUserDataWithRefreshMutation } from 'services/user/user';
import en from "languages/en.json";
import { getValueFromLS } from 'helpers'
import { routes, tokens } from 'invariants';

import theme from './theme'

import './assets/scss/index.scss';

const language = "en";
const messages = {
  en,
};

let interval: any = null
const pollingInterval = (10 * 60) * 1000

function App() {
  const { isSuccess } = useSelector(selectUserData);
  const [getUserDataWithRefreshToken] = useGetUserDataWithRefreshMutation();

  const polling = () => {
    interval = setInterval(() => {
      const token = getValueFromLS(tokens.accessToken)

      if (token) {
        getUserDataWithRefreshToken({})
      } else {
        clearInterval(interval)
      }
    }, pollingInterval);
  }
  useEffect(() => {
    const token = getValueFromLS(tokens.accessToken)

    if (token && !isSuccess) {
      getUserDataWithRefreshToken({})
      polling()
    } else if (token && isSuccess) {
      polling()
    }
  },
    // eslint-disable-next-line
    [isSuccess])

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <IntlProvider locale={language} messages={messages[language]}>

          <Routes>
            <Route path={routes.adminCompanies} element={
              <AdminGuard>
                <Dashboard />
              </AdminGuard>
            } />
            <Route path={routes.adminDetailedCompanyProfile} element={
              <AdminGuard>
                <DetailedCompany>
                  <CompanyProfile />
                </DetailedCompany>
              </AdminGuard>
            } />
            <Route path={routes.adminDetailedCompanyUsers} element={
              <AdminGuard>
                <DetailedCompany>
                  <UserManagement />
                </DetailedCompany>
              </AdminGuard>
            } />
            <Route path={routes.adminManagement} element={<AdminManagement />} />

            {/* Auth start */}
            <Route
              path={routes.signIn}
              element={<SignIn />} />
            <Route
              path={routes.passwordRecovery}
              element={<PasswordRecovery />}
            />
            <Route
              path={routes.setPassword}
              element={<CreatePassword />}
            />
            <Route
              path={routes.newPassword}
              element={<RestorePassword />}
            />

            {/* Auth end */}

            {/* User Side Start */}
            <Route
              path={routes.clientDashboardContent}
              element={
                <ClientContent autoRedirect>
                  <div />
                </ClientContent>
              }
            />
            <Route
              path={routes.clientDashboard}
              element={
                <ClientContent>
                  <DashboardContent />
                </ClientContent>
              }
            />
            {/* User Side end */}

            <Route
              path="*"
              element={<Navigate to={routes.signIn} replace />}
            />
            <Route
              path={routes.adminDetailedCompanyData}
              element={<Navigate to='/profile' replace />}
            />

          </Routes>
        </IntlProvider>
      </StyledEngineProvider>
      <ToastifyContainer />
    </ThemeProvider>
  );
}

const AppRouter = () => {
  return (
    <Router>
      <App />
    </Router>
  )
}
export default AppRouter;
