import React, { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import clsx from 'clsx'

import { close, arrowDown } from 'assets/icons'

import { useIntlHelper } from 'hooks'

import './styles.scss'

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultipleSelectCheckmarks: React.FC<any> = ({
  register,
  data,
  values = [],
  isClearable = true,
  fieldName,
  setValue,
  label,
  ...restProps
}: any) => {
  const { getIntlMessage } = useIntlHelper()

  const [results, setResults] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const { ref, onBlur, onChange, name } = register(fieldName)

  const handleChange = (event: SelectChangeEvent<typeof results>) => {
    const {
      target: { value },
    } = event;

    onChange(event)
    setResults(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(() => {
    setResults(values)
  }, [values])

  return (
    <div className="select-wrapper">
      <InputLabel shrink className="outlined-label neutral-500">
        {label}
      </InputLabel>
      <FormControl sx={{ width: "100%", height: 40 }}>
        <Select
          ref={ref}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onBlur={onBlur}
          name={name}
          sx={{ height: "40px" }}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          className={
            clsx('custom-select', {
              "zero-padding": isClearable
            })
          }
          multiple
          value={results}
          onChange={handleChange}
          MenuProps={MenuProps}
          endAdornment={
            <InputAdornment position="end">
              {results.length > 0 && isClearable && (
                <IconButton className='clear-select-icon' onClick={(e) => {
                  setResults([])
                  setValue(name, [])
                }}>
                  <img src={close} alt="clear" />
                </IconButton>
              )}
              <IconButton className={clsx('arrow-select', { 'select-open': open })} onClick={() => setOpen(!open)}>
                <img src={arrowDown} alt="open/close" />
              </IconButton>
            </InputAdornment>
          }
          {...restProps}
        >
          {data.map((name: string) => (
            <MenuItem key={name} value={name} className={clsx("custom-select-dropdown", {
              "is-selected": results.indexOf(name) > -1
            })}>
              <Checkbox checked={results.indexOf(name) > -1} />
              <ListItemText className='item-title' primary={getIntlMessage(name)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default MultipleSelectCheckmarks