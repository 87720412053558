import React from 'react'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import clsx from 'clsx'

import './styles.scss'

export type ButtonRefType = MuiButtonProps['ref']

export interface ButtonProps extends MuiButtonProps {
  disableSpacing?: boolean
  disableTextTransform?: boolean
  disableUpperCase?: boolean
  href?: string
  to?: string
}

const Button = React.forwardRef(function Button(
  {
    children,
    variant = "contained",
    disableUpperCase,
    className,
    sx = {},
    ...other
  }: ButtonProps,
  ref: ButtonRefType
) {

  return (
    <MuiButton
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'flex',
        alignItems: 'center',
        textTransform: disableUpperCase ? "none" : "inherit",
        ...sx
      }}
      {...other}
      className={clsx('mui-button-override', className)}
      classes={{
        disabled: "mui-disabled-btn"
      }}
      variant={variant} ref={ref}>
      {children}
    </MuiButton>
  )
})

export default Button
