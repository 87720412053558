import React, { useRef, useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel';
import { toast } from "react-toastify";
import { Tooltip } from '@mui/material';

import DialogTitle from 'components/UI/DialogTitle'
import DialogContent from 'components/UI/DialogContent'
import DialogActions from 'components/UI/DialogActions'
import Button from 'components/UI/Button'
import OutlinedInput from "components/UI/OutlinedInput";
import DatePicker from 'components/common/DatePicker'
import { ReactIf } from 'components/common/ReactIf'
import FormControlSelect from 'components/UI/FormControlSelect'
import LogoArea from 'components/common/LogoArea'
import Multiselect from 'components/common/Multiselect'
import { useAvatarCropDialog } from 'components/common/AvatarCropDialog'
import useIntlHelper from "hooks/useIntlHelper";
import { statusList, toastDefaultSettings } from 'invariants'
import { modifyS3Url } from 'helpers'
import { LogoData } from 'types';

import './style.scss'

export type CompanyInfoStepProps = {
  onClose: () => void
  btnType?: 'button' | 'submit' | 'reset'
  btnName: string
  onApply: any
  register: any
  control: any
  setValue: any
  dialogClassName: string
  setLogoData: (v: LogoData | {}) => void
  isEditMode: boolean
  manageProgress: boolean
  logoData: LogoData | {}
  getValues: any
  watch: any
}

const names = [
  'competitor_public_pricing_data',
  'e-commerce_pricing',
  'contract_pricing_monitor',
  'standard_cost',
  'automate_cost_data_handling',
];

const modules = [
  'price_optimization',
  'inventory_optimization',
  "advance_evaluation",
  "professional_services"
];

const CompanyInfoStep: React.FC<CompanyInfoStepProps> = ({
  onClose,
  btnType,
  setValue,
  btnName,
  onApply,
  register,
  control,
  dialogClassName,
  setLogoData,
  isEditMode,
  logoData,
  getValues,
  manageProgress,
  watch
}) => {
  const [isStepValid, setStepValid] = useState<boolean>(false)

  const companyName = watch('name')
  const contactName = watch('main_contact_full_name')
  const start_date = watch('start_date')

  const saveLogosData = (data: LogoData) => {
    setLogoData(data)
  }

  const avatarCropDialog = useAvatarCropDialog(saveLogosData)
  const { getIntlMessage } = useIntlHelper()
  const inputRef = useRef(null);

  const prepareImageForEditLogo = () => {
    let logoSrc: string | null = ''
    const lgData: LogoData = logoData as LogoData

    if ((lgData?.logo_original || '').startsWith("data:image")) {
      logoSrc = lgData?.logo_original
    } else if ((lgData?.logo_original || "").includes('s3.us-east-2.amazonaws.com')) {
      logoSrc = lgData?.logo_original
    } else {
      logoSrc = modifyS3Url(lgData?.logo_original || '')
    }

    avatarCropDialog.setOpen(true, logoSrc || '');
  }


  const handleButtonClick = () => {
    if ((logoData as LogoData)?.logo_original) {
      prepareImageForEditLogo()

      return
    }

    if (inputRef?.current) {
      //@ts-ignore
      inputRef.current.click();
    }
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (!file) {
      return
    }
    // Check file size
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
    if (file.size > maxSizeInBytes) {
      toast.warning('File size exceeds 5MB limit.', toastDefaultSettings)
      return;
    }

    // Check file format
    const allowedFormats = ["jpeg", "png", "jpg"];
    const extension = file.name.split(".").pop()?.toLowerCase();
    if (!extension || !allowedFormats.includes(extension)) {
      toast.warning("Invalid file format. Only JPEG, PNG, and JPG formats are allowed.", toastDefaultSettings)
      return;
    }

    const reader: any = new FileReader();
    reader.onload = () => {
      //@ts-ignore
      avatarCropDialog.setOpen(true, reader.result);
      e.target.value = null
    };
    reader.readAsDataURL(file);

  };

  useEffect(() => {
    setStepValid([companyName, contactName, start_date].every(v => Boolean(v)))
  },
    [companyName, contactName, start_date, setStepValid])

  return (
    <div className={dialogClassName} >
      <DialogTitle>
        {getIntlMessage(isEditMode ? 'editCompany' : 'newCompany')}
        <ReactIf value={!isEditMode}>
          <p>Step 1 of 2</p>
        </ReactIf>
      </DialogTitle>

      <DialogContent>
        <OutlinedInput
          label="companyNameField"
          required
          register={register("name")}
        />

        <div className='input-logo'>
          <InputLabel shrink className="outlined-label neutral-500">
            Company Logo (optional)
          </InputLabel>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            ref={inputRef}
            style={{ display: 'none' }}
          />

          <LogoArea logoData={logoData} onLogoAction={handleButtonClick} />
        </div>

        <OutlinedInput label="contactName"
          required register={register("main_contact_full_name")} />

        <div className='dialog-select-container'>
          <Multiselect
            label="Module (optional)"
            fieldName='module'
            register={register}
            setValue={setValue}
            values={getValues('module') || []}
            data={modules}
            renderValue={(selected: string[]) => {
              return selected.map((name: string) => getIntlMessage(name)).join(", ")
            }}
          />
        </div>

        <div className='dialog-select-container'>
          <Multiselect
            label="Add On (optional)"
            fieldName='add_on'
            register={register}
            setValue={setValue}
            values={getValues('add_on') || []}
            data={names}
            renderValue={(selected: string[]) => {
              return selected.map((name: string) => getIntlMessage(name)).join(", ")
            }}
          />
        </div>

        <DatePicker
          control={control}
          fieldName="start_date"
          label="Start Date"
        />

        <div className='mt-12 mui-override-hover'>
          <FormControlSelect
            name="status"
            control={control}
            selectLabel={getIntlMessage("statusField")}
            values={
              Object.values(statusList).map((status) => ({
                value: status,
                label: getIntlMessage(`${status}_lb`)
              }))}
            defaultValue="active"
            renderValue={(label: any) => label ? getIntlMessage(`${label}_lb`) : ''}
          />
        </div>

      </DialogContent >

      <DialogActions  >
        <Button onClick={onClose} variant='outlined'>
          {getIntlMessage("cancel")}
        </Button>

        <Tooltip title={isStepValid ? "" : getIntlMessage("please_fill_all_mandatory_fields")} placement='top'>
          <div>
            <Button
              type={btnType}
              disabled={!isStepValid || manageProgress}
              {...(btnType === 'submit' ? {} : { onClick: onApply })} >
              {getIntlMessage(btnName)}
            </Button>
          </div>
        </Tooltip>

      </DialogActions>

      {avatarCropDialog.el}
    </div >
  )
}

export default CompanyInfoStep