import { useState } from "react"
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom'

import {
  useCreateUserMutation,
  useEditUserMutation,
  manageSuperUserDataMorphism
} from 'services/company';
import useIntlHelper from 'hooks/useIntlHelper'
import { isEmpty } from 'helpers'
import { User } from 'types/user'
import { toastDefaultSettings } from 'invariants'
import { RolesType } from 'types/general'

import ManageUserDialog from "./ManageUserDialog"

type useManageUserDialogOptions = {
  refreshAfterAction: () => void
  title?: string
  editTitle?: string
  roles: RolesType[]
  type: string
}

export const useManageUserDialog = ({
  roles,
  refreshAfterAction,
  title = 'newUser',
  editTitle = "editUser",
  type
}: useManageUserDialogOptions) => {
  const params = useParams()
  const [isEditMode, setIsEditMode] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [userData, setUserData] = useState<User | {}>({})
  const [error, setError] = useState('')
  const [createNewUser] = useCreateUserMutation();
  const [editUser] = useEditUserMutation();
  const { getIntlMessage } = useIntlHelper()

  const handleSetOpen = (open: boolean, data: User | {}) => {
    setOpen(open)
    setUserData(data)
    setIsEditMode(!isEmpty(data as any))
  }

  const actionAfterManageUser = (resp: any) => {
    if (!resp.hasOwnProperty('error')) {
      refreshAfterAction()
      toast.success(getIntlMessage(`successfully_message_after_${isEditMode ? 'edit' : 'create'}_${type}`), toastDefaultSettings)
      handleClose()
    } else if (Array.isArray(resp?.error?.data?.errors)) {
      setError((resp?.error?.data?.errors || [])[0])
    } else {
      setError((resp?.error?.data?.errors?.email || [])[0] || '')
    }
  }

  const handleCreate = (values: any) => {
    if (isEditMode) {
      editUser({
        data: manageSuperUserDataMorphism({
          ...values,
          companyId: params?.id,
        }),
        userId: (userData as User)?.id
      })
        .then(actionAfterManageUser)
    } else {
      createNewUser(manageSuperUserDataMorphism({
        ...values,
        companyId: params?.id,
      }))
        .then(actionAfterManageUser)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setIsEditMode(false)
    setUserData({})
  }


  return {
    el: (
      <ManageUserDialog
        open={isOpen}
        onClose={handleClose}
        onApply={handleCreate}
        setError={setError}
        error={error}
        isEditMode={isEditMode}
        userData={userData as User}
        title={title}
        editTitle={editTitle}
        roles={roles}
      />
    ),
    setOpen: handleSetOpen
  }
}
