import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";

import OutlinedInput from "components/UI/OutlinedInput";
import Button from "components/UI/Button";
import { routes } from 'invariants'

import AuthModule from '../Auth'
import { usePageLogic } from "./usePageLogic";

import "./styles.scss";

const SignIn = () => {
  const { register, setValue, handleSubmit, getValues } = useForm();
  const { formHandler, isError, errors } = usePageLogic();

  useEffect(() => {
    setValue('username', getValues('username'));
    setValue('password', getValues('password'));
  },
    // eslint-disable-next-line
    [])

  return (
    <AuthModule>
      <div className="sign-in-wrapper">
        <h3 className="auth-title main-text-color">
          <FormattedMessage id="signIn" />
        </h3>

        {
          isError && (
            <div className="error-message-block">
              {errors[0]}
            </div>
          )
        }

        <form onSubmit={handleSubmit(formHandler)} className="login-form">
          <OutlinedInput
            error={isError}
            placeholder="enterEmail" label="emField" type="email" required={true} register={register("username")} />

          <OutlinedInput
            error={isError}
            showPasswordLogic placeholder="authPassword" label="authPassword" type="password" required={true} register={register("password")} />

          <div className="recovery-block">
            <NavLink className='recovery-link' to={routes.passwordRecovery}>Forgot password?</NavLink>
          </div>

          <Button className="submit-button" type="submit" disableUpperCase fullWidth>
            <FormattedMessage id="signIn" />
          </Button>
        </form>
      </div>

    </AuthModule>
  );
};

export default SignIn;
