import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import ActionDialog from 'components/common/ActionDialog'
import { useRemoveDashboardMutation } from 'services/dashboard'

import Context from '../Context'

const useRemoveDashboard = () => {
  const navigate = useNavigate()

  const [open, setOpen] = useState<boolean>(false)
  const { companyId, dashboardId, getDashboards } = useContext(Context)

  const [removeDashboard] = useRemoveDashboardMutation()

  const onApply = () => {
    removeDashboard({
      companyId,
      dashboardId,
    }).then(() => {
      navigate(`/user/company/${companyId}`)
      getDashboards(true)
      setOpen(false)
    })
  }

  return {
    setOpen: setOpen,
    el: (
      <ActionDialog
        onClose={() => setOpen(false)}
        onApply={onApply}
        open={open}
        ids={{
          dialogTitle: "delete_dashboard",
          dialogContent: "delete_dashboard_confirmation",
          actionCancel: 'no',
          actionApply: 'yes',
        }}
      />
    )
  }
}

export default useRemoveDashboard