import { statusList } from 'invariants';
import { Company } from 'types'

export const prepareRow = (data: Company) => {
  if (!data.add_on) {
    return {
      ...data,
      add_on: []
    }
  }

  if (typeof data.add_on === 'string') {
    return {
      ...data,
      //@ts-ignore
      add_on: data.add_on.split(', ')
    }
  }

  return data
}

export const statusFiltering = [
  {
    statusKey: statusList.active,
    lbl: "Active"
  },
  {
    statusKey: statusList.inactive,
    lbl: "Inactive"
  },
]

export const headerCells = [
  {
    id: "name",
    label: "Company Name",
    enableSorting: true,
  },
  {
    id: "main_contact_full_name",
    label: "Main Contact Full Name",
    enableSorting: true,
  },
  {
    id: "module",
    label: "Module",
  },
  {
    id: "add_on",
    label: "Add On",
  },
  {
    id: "start_date",
    label: "Start Date",
    enableSorting: true,
  },
  {
    id: "status",
    label: "Status",
    enableFiltering: true,
  },
  {
    id: "participants",
    label: "Associated Users",
    enableSorting: false,
  },
  {
    id: "actions",
    label: "",
  }
]

export const statuses = Object.values(statusList)