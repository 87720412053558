import { useState } from "react"
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { useCreateCompanyMutation, createCompanyDataMorphism, editCompanyDataMorphism } from 'services/admin';
import { useEditCompanyInfoMutation } from 'services/company'
import { useIntlHelper } from 'hooks'
import { formatDate, isEmpty, modifyS3Url, prepareFieldsForEditLogo } from 'helpers'
import { toastDefaultSettings } from 'invariants'
import { DetailedCompanyData, LogoData } from 'types'

import ManageCompanyDialog from "./ManageCompanyDialog"

export type CompanyData = DetailedCompanyData | {}

export const useManageCompanyDialog = (options: any) => {
  const {
    refreshData,
    isEditMode = false,
  } = options
  const params = useParams()

  const [open, setOpen] = useState(false)
  const [companyData, setCompanyData] = useState<CompanyData>({})
  const [dialogType, setDialogType] = useState<number>(0)
  const [logoData, setLogoData] = useState<LogoData | {}>({})
  const [createNewCompany, { isLoading: isLoadingCreateCompany }] = useCreateCompanyMutation();
  const [editCompanyData, { isLoading: isLoadingEditCompany }] = useEditCompanyInfoMutation();
  const { getIntlMessage } = useIntlHelper()

  const handleSetOpen = (open: boolean, data: CompanyData = {}, type: number = 0) => {
    setOpen(open)
    if (Object.keys(data).length) {
      setCompanyData(data)
      setDialogType(type)
      setLogoData({
        logo_original: (data as DetailedCompanyData)?.logo_original_url,
        logo_cropped: (data as DetailedCompanyData)?.logo_cropped_url,
      })
    } else {
      setLogoData({
        logo_original: '',
        logo_cropped: '',
      })
    }
  }

  const handleManageData = (values: any) => {
    const data = {
      ...values,
      add_on: isEmpty(values.add_on) ? null : values.add_on,
      module: isEmpty(values.module) ? null : values.module,
      start_date: formatDate(values.start_date, "YYYY-MM-DD") || null,
      companyId: params?.id,
    }

    const handleActionAfterManageData = (resp: any) => {
      if (!resp.hasOwnProperty('error')) {
        refreshData()
        toast.success(getIntlMessage(`successfully_message_after_${isEditMode ? 'edit' : 'create'}_company`), toastDefaultSettings)
        handleSetOpen(false)
      } else if (Array.isArray(resp?.error?.data?.errors)) {
        toast.error(resp?.error?.data?.errors[0], toastDefaultSettings)
      } else {
        toast.error('Oops. Something went wrong. Please try again', toastDefaultSettings)
      }


    }

    if (isEditMode) {
      const updatedLogoFields = prepareFieldsForEditLogo({
        ...logoData,
        //@ts-ignore
        logo_original: logoData?.logo_original === modifyS3Url(companyData?.logo_original_url) ? companyData?.logo_original_url : logoData?.logo_original,
      })

      editCompanyData({
        data: editCompanyDataMorphism({
          ...data,
          ...updatedLogoFields
        }),
        companyId: params?.id
      })
        .then(handleActionAfterManageData)
    } else {
      createNewCompany(createCompanyDataMorphism({
        ...data,
        ...logoData
      }))
        .then(handleActionAfterManageData)
    }
  }

  return {
    el: (
      <>
        {open ? (
          <ManageCompanyDialog
            open={open}
            manageProgress={isLoadingCreateCompany || isLoadingEditCompany}
            onClose={() => setOpen(false)}
            onApply={handleManageData}
            logoData={logoData}
            setLogoData={setLogoData}
            companyData={companyData}
            isEditMode={isEditMode}
            dialogType={dialogType}
          />
        ) : null}
      </>
    ),
    setOpen: handleSetOpen
  }
}