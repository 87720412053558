import { ReactNode } from 'react';
import MuiDialogActions, { DialogActionsProps as MuiDialogActionsProps } from '@mui/material/DialogActions';

import './styles.scss'

export type DialogActionsProps = MuiDialogActionsProps & {
  children: ReactNode
}

const DialogActions: React.FC<DialogActionsProps> = ({
  children
}) => {
  return (
    <MuiDialogActions className='dialog-action-wrapper'>
      {children}
    </MuiDialogActions>
  )
}

export default DialogActions