import { createSlice } from '@reduxjs/toolkit';

const signInSlice = createSlice({
  name: 'signIn',
  initialState: {
    data: null,
    error: null,
  },
  reducers: {
    onSignInSuccess(state, action) {
      state.data = action.payload;
      state.error = null;
    },
    onSignInFailure(state, action) {
      state.error = action.payload;
    },
  },
});

export const { onSignInSuccess, onSignInFailure } = signInSlice.actions;

export const selectSignInData = (state: any) => state.signIn;

export default signInSlice.reducer;