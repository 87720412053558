import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { NavLink } from 'react-router-dom';

import OutlinedInput from "components/UI/OutlinedInput";
import Button from "components/UI/Button";
import { routes } from 'invariants';

import AuthModule from '../Auth'
import { usePageLogic } from "./usePageLogic";

import "./styles.scss";

const PasswordRecovery = () => {
  const { register, handleSubmit } = useForm();
  const { formHandler, isError, errors } = usePageLogic();

  return (
    <AuthModule>
      <section className="recovery-page">

        <h3 className="auth-title main-text-color mb-12">
          <FormattedMessage id="passwordRecoveryTitle" />
        </h3>

        <p className="main-text-color mb-12 recovery-subtext">
          <FormattedMessage id="passwordRecoveryDescription" />
        </p>

        {
          isError && (
            <div className="error-message-block">
              {errors[0]}
            </div>
          )
        }

        <form onSubmit={handleSubmit(formHandler)}>
          <OutlinedInput
            placeholder="enterEmail" label="emField" type="email" required={true} register={register("email")} />

          <div className="box-actions">
            <Button variant="text" fullWidth className="return-to-auth" component={NavLink} to={routes.signIn}>
              <FormattedMessage id="backToSignIn" />
            </Button>

            <Button type="submit" fullWidth >
              <FormattedMessage id="sendEmail" />
            </Button>
          </div>

        </form>
      </section>
    </AuthModule>
  );
};

export default PasswordRecovery;
