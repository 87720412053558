import { tokens } from 'invariants'

export const saveUserTokensInLocalStorage = ({
  access_token,
  refresh_token,
}: {
  [key: string]: string
}) => {
  localStorage.setItem(tokens.accessToken, JSON.stringify(access_token))
  localStorage.setItem(tokens.refreshToken, JSON.stringify(refresh_token))
}

export const clearTokens = () => {
  localStorage.removeItem(tokens.accessToken)
  localStorage.removeItem(tokens.refreshToken)
}

export const getValueFromLS = (name: string) => {
  const value = localStorage.getItem(name)

  if (!value) {
    return undefined
  }

  return JSON.parse(value)
}

export const setValueToLS = (name: string, value: any) => {
  localStorage.setItem(name, JSON.stringify(value))
}