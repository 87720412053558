import React, { useContext } from 'react'
import IconButton from '@mui/material/IconButton';

import Button from 'components/UI/Button'
import ActionDialog from 'components/common/ActionDialog'
import { ReactIf } from 'components/common/ReactIf'
import { logoWhite, logoutIcon, plus } from 'assets/icons';
import TextWithTooltip from 'components/common/TextWithTooltip';
import { useIntlHelper } from 'hooks'

import ClientContext from '../Context'
import usePageLogic from './usePageLogic'
import TabList from './TabList'

import './styles.scss'
import { isEmpty } from 'helpers';

const Drawer: React.FC = () => {
  const { getIntlMessage } = useIntlHelper()
  const {
    drawerTabs,
    hiddenTabs,
    setDrawerTabs,
    setHiddenTabs,
    isEditMode,
    isSuperAdmin,
  } = useContext(ClientContext)

  const {
    setOpenLogoutDialog,
    userName,
    logout,
    openLogoutDialog,
    addDashboard,
  } = usePageLogic()

  return (
    <div className='client-drawer'>
      <div className='drawer-container'>
        <img className="intuilize-logo" src={logoWhite} alt="logo-light" />

        <ReactIf value={isEditMode && isSuperAdmin}>
          <Button
            startIcon={<img src={plus} alt="add icon" />}
            variant='outlined'
            onClick={() => addDashboard.setOpen(true)}
            className='add-new-dashboard-btn'
            fullWidth
          >
            {getIntlMessage('new_dashboard')}
          </Button>
        </ReactIf>

        <nav className='client-navigation'>
          <ul>
            <TabList data={drawerTabs} positionsBefore={0} setDataOrder={setDrawerTabs} />
          </ul>

          <ReactIf value={isSuperAdmin && !isEmpty(hiddenTabs)}>
            <h3 className='hidden-title'>{getIntlMessage("hidden_dashboard")}</h3>
            <ul>
              <TabList data={hiddenTabs} positionsBefore={drawerTabs.length} setDataOrder={setHiddenTabs} />
            </ul>
          </ReactIf>
        </nav>
      </div>

      <ReactIf value={!isEditMode}>
        <div className='drawer-tab profile-tab'>
          <TextWithTooltip text={userName} className="tab-content" />
          <IconButton aria-label="logout" onClick={() => setOpenLogoutDialog(true)}
            className='logout-icon'>
            <img
              src={logoutIcon}
              alt="logout"
            />
          </IconButton>
        </div>
      </ReactIf>

      <ActionDialog
        onClose={() => setOpenLogoutDialog(false)}
        onApply={logout}
        open={openLogoutDialog}
        ids={{
          dialogTitle: "logOut",
          dialogContent: "confirmLogOut"
        }}
      />
      {addDashboard.el}
    </div>
  )
}

export default Drawer