import { useEffect, useState } from 'react'
import { FieldValues } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

import { useSetPasswordMutation } from 'services/auth/authService';
import { routes, toastDefaultSettings } from 'invariants';
import useIntlHelper from "hooks/useIntlHelper";

export type SignInLogicOptions = {
  formHandler: (v: FieldValues) => void;
  isError: boolean
  errors: Array<string>
};

export const usePageLogic = (isNewUser: boolean): SignInLogicOptions => {
  const [errors, setErrors] = useState([])
  const location = useLocation();
  const navigate = useNavigate()
  const { getIntlMessage } = useIntlHelper()

  const [setPassword, { isError }] = useSetPasswordMutation();

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const formHandler = (values: FieldValues) => {
    setPassword({
      data: {
        ...values,
        token
      },
      isNewUser
    })
      .then((response: any) => {
        if (response.hasOwnProperty('error')) {
          setErrors(response.error.data.errors || [])
        } else {
          toast.warning(getIntlMessage(`notification_after_${isNewUser ? "create" : 'reset'}_password`), toastDefaultSettings)
          navigate(routes.signIn);
        }
      })
  };

  useEffect(() => {
    if (!token) {
      navigate(routes.signIn);
    }
  },
    // eslint-disable-next-line
    [])

  return {
    formHandler,
    isError,
    errors
  };
};
