import { useState } from "react"
import { toast } from "react-toastify";

import { useChangeUserStatusMutation } from "services/user/user";
import { useChangeCompanyStatusMutation } from 'services/company'
import useIntlHelper from 'hooks/useIntlHelper'
import { toastDefaultSettings } from 'invariants'
import { Company } from 'types/company'
import { User } from 'types/user'

import ChangeUserStatusDialog from "./ChangeUserStatusDialog"

type dialogCD = () => void

export const useChangeStatusDialog = (refreshAfterAction: dialogCD, type: string) => {
  const [isOpen, setOpen] = useState(false)
  const [rowData, setRowData] = useState<any>({
    newStatus: "active"
  })

  const { getIntlMessage } = useIntlHelper()
  const [changeUserStatus] = useChangeUserStatusMutation()
  const [changeCompanyStatus] = useChangeCompanyStatusMutation()

  const handleSetOpen = (open: boolean, row: Company | User, newStatus: string) => {
    setOpen(open)
    setRowData({
      ...row,
      newStatus
    })
  }

  const onApply = () => {
    const correctRequest: any = type === 'user' ? changeUserStatus : changeCompanyStatus

    correctRequest({ id: rowData?.id, status: rowData?.newStatus })
      .then((resp: any) => {
        if (!resp.hasOwnProperty('error')) {
          refreshAfterAction()
          toast.success(getIntlMessage(`successfully_message_after_change_${type}_status`), toastDefaultSettings)
          setOpen(false)
        }
      })
  }

  return {
    el: (
      <ChangeUserStatusDialog
        open={isOpen}
        onClose={() => setOpen(false)}
        onApply={onApply}
        row={rowData}
        type={type}
      />
    ),
    setOpen: handleSetOpen
  }
}
