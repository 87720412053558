import React from 'react'
import MenuItem from '@mui/material/MenuItem';
import { Controller } from "react-hook-form";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { arrowDown } from 'assets/icons'

import './styles.scss'

export type FormControlSelectProps = {
  control: any
  name: string
  defaultValue: string
  values: Array<any>
  selectLabel: string
  renderValue?: any
}

const IconComponent = ({ className }: { className: string }) => (
  <img src={arrowDown} className={className} alt="t" />
)

const FormControlSelect: React.FC<FormControlSelectProps> = ({
  control,
  name,
  defaultValue = "",
  values,
  selectLabel,
  ...rest
}) => {
  return (
    <FormControl sx={{ mb: 2, }}
      fullWidth
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }: any) => (
          <>
            <span className="controlled-label neutral-500">{selectLabel}</span>
            <Select
              {...rest}
              {...field}
              displayEmpty
              sx={{ height: '40px', width: '100%' }}
              inputProps={{ 'aria-label': 'Without label' }}
              IconComponent={IconComponent}
            >
              {
                values.map(({ value, label }: any) => (
                  <MenuItem key={value} value={value}  >{label}</MenuItem>
                ))
              }
            </Select></>
        )}
      />
    </FormControl>
  )
}

export default FormControlSelect