// import { useContext } from 'react'

// import Chart from './ChartComponent'
// import KPIComponent from './KPIComponent'
// import CollapsibleTable from './CollapsibleTable'
import { AddButton } from './AddButton'
import useWidgetList from './WidgetList'
// import Context from '../../Context'

import './styles.scss'

// const sizes = {
//   chart: 0.5,
//   kpi: 0.5,
//   table: 1
// }


// const DataDisplay = ({ data, onAdd, onRemove }: any) => {

//   const isLastItemEmpty =
//     data.length > 0 && data[data.length - 1].type === "empty";

//   const renderItems = () => {
//     let components: any = [];
//     let charts: any = [];
//     let lastChart = false;

//     data.forEach((item: any, index: any) => {
//       lastChart = false;

//       if (sizes[item.type] === 0.5) {
//         const component = item.type === 'chart' ? <Chart onRemove={() => onRemove(index)} /> : <KPIComponent onRemove={() => onRemove(index)} />
//         charts.push(
//           <div key={`chart-${index}`} style={{ width: "50%", height: "368px", minWidth: "300px", overflowX: 'auto', backgroundColor: "#fff", borderRadius: '4px' }}>
//             {component}
//           </div>
//         );

//         if (
//           charts.length === 2 ||
//           index === data.length - 1 ||
//           sizes[data[index + 1]?.type] !== 0.5
//         ) {
//           lastChart = charts.length === 1;
//           components.push(
//             <div
//               key={`chart-row-${index}`}
//               className="chart-wrapper-container"
//             >
//               {charts}
//               {charts.length === 1 && (
//                 <div style={{ width: "50%" }}>
//                   <AddButton onClick={onAdd} style={{ height: "100%" }} />
//                 </div>
//               )}
//             </div>
//           );
//           charts = [];
//         }
//       } else if (sizes[item.type] === 1) {
//         if (charts.length > 0) {
//           components.push(
//             <div
//               key={`chart-row-${index}`}
//               className="chart-wrapper-container"
//             >
//               {charts}
//               {charts.length === 1 && (
//                 <div style={{ width: "50%" }}>
//                   <AddButton onClick={onAdd} />
//                 </div>
//               )}
//             </div>
//           );
//           charts = [];
//         }
//         components.push(
//           <div key={`table-${index}`} style={{ width: "100%" }}>
//             <CollapsibleTable onRemove={() => onRemove(index)} />
//           </div>
//         );
//       }
//     });

//     return {
//       components,
//       lastChart,
//     };
//   };

//   if (data.length === 0) {
//     return (
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           gap: "20px",
//           width: "100%",
//         }}
//       >
//         <AddButton onClick={onAdd} />
//       </div>
//     );
//   }

//   const { components, lastChart } = renderItems();
//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         gap: "20px",
//         width: "100%",
//       }}
//     >
//       {components}
//       {!isLastItemEmpty && !lastChart && <AddButton onClick={onAdd} />}
//     </div>
//   );
// };

const App = () => {
  // const { widgetsList, onRemoveWidget } = useContext(Context)
  const widgetList = useWidgetList()

  return (
    <div
      style={{
        width: "100%",
        margin: "0 auto",
        position: "relative",
      }}
    >
      {/* <DataDisplay data={widgetsList} onAdd={widgetList.openWidgetsList} onRemove={onRemoveWidget} /> */}
      <AddButton style={{}} />
      {widgetList.el}
    </div>
  );
};

export default App;
