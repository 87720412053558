import { useForm } from "react-hook-form";
import clsx from 'clsx'

import Dialog, { DialogProps } from 'components/UI/Dialog'
import DialogTitle from 'components/UI/DialogTitle'
import DialogContent from 'components/UI/DialogContent'
import DialogActions from 'components/UI/DialogActions'
import Button from 'components/UI/Button'
import OutlinedInput from "components/UI/OutlinedInput";
import FormControlSelect from 'components/UI/FormControlSelect'
import useIntlHelper from "hooks/useIntlHelper";
import { User } from 'types/user'

import './styles.scss'
import { useEffect } from "react";
import { isEmpty } from "helpers";

type ManageCompanyDialogProps = DialogProps & {
  onClose: () => void
  onApply: (v: any) => void
  error: string
  setError: (v: string) => void
  isEditMode: boolean
  userData: User
  title: string
  editTitle: string
  roles: Array<{
    value: string,
    label: string
  }>
}

const ManageUserDialog = (props: ManageCompanyDialogProps) => {
  const { register, handleSubmit, control, watch, setValue, reset } = useForm();
  const {
    onApply,
    error,
    setError,
    isEditMode,
    userData,
    title,
    editTitle,
    roles,
    ...restProps
  } = props
  const { getIntlMessage } = useIntlHelper()
  const email = watch('email');

  useEffect(() => {
    setError('')
  },
    // eslint-disable-next-line
    [email])


  useEffect(() => {
    if (isEditMode) {
      setValue("fullName", userData?.full_name)
      setValue("email", userData?.email)
      setValue("role", userData?.role)
    } else {
      reset()
    }
  },
    // eslint-disable-next-line
    [isEditMode, userData])

  return (
    <Dialog
      {...restProps}
      fullWidth
      className={clsx('manageCompany-dialog-container')}
      classes={{ paper: 'paper-container' }}
    >
      <DialogTitle>
        {getIntlMessage(isEditMode ? editTitle : title)}
      </DialogTitle>

      <form onSubmit={handleSubmit(onApply)}>
        <DialogContent>
          <OutlinedInput
            label="fullName" required register={register("fullName")} />

          <OutlinedInput
            errorMessage={error}
            error={!isEmpty(error)}
            label="emField" required register={register("email")} />

          <FormControlSelect
            name="role"
            control={control}
            selectLabel={getIntlMessage("roleField")}
            values={roles}
            defaultValue={roles[0]?.value as string}
          />

        </DialogContent>

        <DialogActions  >
          <Button onClick={props.onClose} variant='outlined'>
            {getIntlMessage("cancel")}
          </Button>

          <Button type="submit">
            {getIntlMessage(isEditMode ? 'saveChanges' : "sendInvitation")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ManageUserDialog