import React from 'react'
import Skeleton from '@mui/material/Skeleton';

import './styles.scss'

const PageSkeleton: React.FC = () => (
  <div className="skeleton-container">
    <div className='skeleton-header'>
      <Skeleton height="100%" variant='rectangular' />
    </div>

    <div className="skeleton-content">
      <div className="skeleton-left-block">
        <Skeleton variant='rectangular' height="400px" />
      </div>
      <div className="skeleton-right-block">
        <Skeleton variant='rectangular' height="100%" />
      </div>
    </div>
    <div className="skeleton-footer"> <Skeleton variant='rectangular' height="100%" /></div>
  </div>
)

export default PageSkeleton