import React, { useEffect, useContext } from 'react'
import { useSelector } from "react-redux";

import Button from 'components/UI/Button'
import { useIntlHelper } from 'hooks'
import { ReactIf } from 'components/common/ReactIf'
import { useGetDashboardInfoMutation, useGetDashboardTokenMutation } from 'services/dashboard'
import { errorTrash, bluePencil } from 'assets/icons'
import { selectDashboardData } from '@redux/reducers/dashboardInfo'


import Context from '../Context'
import useManageExternalLink from '../ManageExternalLink'
import useRemoveDashboard from './useRemoveDashboard'
import MockedLayout from './MockedLayout'
import Skeleton from './Skeleton'

import './styles.scss'

export const DashboardContentWrapper: React.FC = () => {
  const { data: dashboardData = {} }: any = useSelector(selectDashboardData);

  const { getIntlMessage } = useIntlHelper()
  const { isEditMode, isUserDataLoaded, companyId, dashboardId } = useContext(Context)
  const [fetchDashboardInfo]: any = useGetDashboardInfoMutation()
  const [fetchDashboardToken, { data: authTokenData }]: any = useGetDashboardTokenMutation()
  const manageExternalLink = useManageExternalLink()

  const removeDashboard = useRemoveDashboard()

  useEffect(() => {
    if (isUserDataLoaded && dashboardId) {
      fetchDashboardInfo({
        companyId, dashboardId
      }).then(() => {
        setTimeout(() => {
          //make a request here
          fetchDashboardToken(companyId)
        }, 500);
      })
    }
  },
    // eslint-disable-next-line
    [isUserDataLoaded, dashboardId])

  if (!authTokenData?.auth_token && dashboardData?.is_external_dashboard) {
    return (
      <div className='widget_base-info'>
        <Skeleton />
      </div>
    )
  }

  //if token exist and dashboard using helico....
  if (authTokenData?.auth_token && dashboardData?.is_external_dashboard) {
    return (
      <>
        {removeDashboard.el}
        <div className='widget_base-info helico-data'>
          <h3 className='dashboard-title'>{dashboardData?.name}</h3>
          <ReactIf value={isEditMode}>
            <div className='dashboard-actions'>
              <Button
                startIcon={<img src={errorTrash} alt="delete dashboard" />}
                variant='text'
                color="error"
                onClick={() => removeDashboard.setOpen(true)}
              >
                {getIntlMessage('delete_dashboard')}
              </Button>

              <Button
                startIcon={<img src={bluePencil} alt="edit dashboard" />}
                variant='text'
                onClick={() => manageExternalLink.setOpen(true, true)}
              >
                {getIntlMessage('edit_dashboard')}
              </Button>

            </div>
          </ReactIf>
        </div>

        {/* eslint-disable-next-line */}
        <iframe
          id='helicoIframe'
          src={`${dashboardData?.link}&mode=dashboard&authToken=${authTokenData?.auth_token}`}
          width="100%" height="100%"
        >
        </iframe>
        {manageExternalLink.el}
      </>
    )
  }

  return (
    <section className='dashboard-content' >
      <div className='widget_base-info'>
        <h3 className='dashboard-title'>{dashboardData?.name}</h3>
        <ReactIf value={isEditMode}>
          <div className='dashboard-actions'>
            <Button
              startIcon={<img src={errorTrash} alt="delete dashboard" />}
              variant='text'
              color="error"
              onClick={() => removeDashboard.setOpen(true)}
            >
              {getIntlMessage('delete_dashboard')}
            </Button>

          </div>
        </ReactIf>
      </div>

      <MockedLayout />
      {removeDashboard.el}
    </section>
  )
}


export const DashboardContent = () => {
  return (
    <>
      {/* eslint-disable-next-line */}
      <iframe
        id='logout-from-helico'
        src={`${process.env.REACT_APP_THIRD_PARTY_URL}/hi-ee/j_spring_security_logout`}
        width="0px" height="0px"
      >
      </iframe>
      <DashboardContentWrapper />
    </>
  )
}