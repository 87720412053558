import { useState } from "react"

import { LogoData } from 'types'

import AvatarCropDialog from "./AvatarCropDialog"

type onUpdateLogoType = (v: LogoData) => void

export const useAvatarCropDialog = (onUpdateLogo: onUpdateLogoType) => {
  const [open, setOpen] = useState(false)
  const [img, setImg] = useState('')

  const handleSetOpen = (open: boolean, img: string) => {
    setImg(img)
    setOpen(open)
  }

  return {
    el: (
      <AvatarCropDialog
        open={open}
        imgSrc={img}
        setOpen={setOpen}
        saveImage={onUpdateLogo}
      />
    ),
    setOpen: handleSetOpen
  }
}
