import {
  createApi,
} from '@reduxjs/toolkit/query/react';

import { getUserDataSuccess, getUserDataFailure } from '@redux/reducers/user.slice';
import { getValueFromLS } from 'helpers'

import baseQueryWithReauth from '../baseQueryWithReauth'

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 1,
  tagTypes: ['Profile'],
  endpoints: (build) => ({
    getUserDataWithRefresh: build.mutation({
      query: () => {
        return {
          url: `auth/refresh`,
          method: "POST",
          body: {
            refresh_token: getValueFromLS('refreshToken')
          },
          onSuccess: getUserDataSuccess,
          onError: getUserDataFailure,
        };
      },
    }),
    getUserData: build.mutation({
      query: () => {
        return {
          url: `auth/me`,
          method: "GET",
          onSuccess: getUserDataSuccess,
          onError: getUserDataFailure,
        };
      },
    }),
    logoutUser: build.mutation({
      query: (data) => {
        return {
          url: `auth/logout`,
          method: "POST",
          body: data
        };
      },
    }),
    getUsersList: build.query<any, any>({
      query: ({ companyId, pageSize, search, pageIndex, descending, sortBy, filterBy }: any) => {
        const filtering = filterBy.map((item: string) => `&user_status=${item}`).join("")
        return {
          url: `companies/${companyId}/users?page=${pageIndex}&size=${pageSize}&query_str=${search}&descending=${descending}&sort_by=${sortBy}${filtering}`,
          method: "GET",
        };
      },
    }),
    removeUser: build.mutation<any, any>({
      query: ({ userId }: any) => {
        return {
          url: `users/${userId}`,
          method: "DELETE",
        };
      },
    }),
    changeUserStatus: build.mutation<any, any>({
      query: ({ id, status }: any) => {
        return {
          url: `users/${id}`,
          method: "PATCH",
          body: {
            status
          }
        };
      },
    }),
  }),
  refetchOnReconnect: true,
});

export const {
  useGetUserDataMutation,
  useGetUserDataWithRefreshMutation,
  useLogoutUserMutation,
  useGetUsersListQuery,
  useRemoveUserMutation,
  useChangeUserStatusMutation,
} = userApi;