import { useEffect } from "react";
import { useForm } from "react-hook-form";

import Dialog, { DialogProps } from 'components/UI/Dialog'
import DialogTitle from 'components/UI/DialogTitle'
import DialogContent from 'components/UI/DialogContent'
import DialogActions from 'components/UI/DialogActions'
import Button from 'components/UI/Button'
import { ReactIf } from "components/common/ReactIf";
import OutlinedInput from "components/UI/OutlinedInput";
import useIntlHelper from "hooks/useIntlHelper";
import { IFormikValues } from 'interfaces'
import { Dashboard } from 'types/dashboard'
import { btnLoader } from 'assets/icons'

import './styles.scss'

type ManageExternalLinkProps = DialogProps & {
  isEditMode: boolean
  dashboardInfo: Dashboard
  isLinkValid: boolean
  isLoading: boolean
  errors: any
  onClose: () => void
  onApply: (v: IFormikValues) => void
}

const DashboardNameComponent: React.FC<any> = ({
  register,
  watch
}) => {
  const dashboardName = watch("dashboardName")

  return (
    <div>
      <OutlinedInput
        required
        label="dashboard_name"
        inputProps={{ maxLength: 40 }}
        register={register("dashboardName")}
      />
      <p className="max-length-area">
        {(dashboardName || '').length || 0}/40
      </p>
    </div>
  )
}

const ManageExternalLink = (props: ManageExternalLinkProps) => {

  const { register, handleSubmit, watch, reset, setValue } = useForm();

  const {
    onApply,
    open,
    isEditMode,
    dashboardInfo,
    isLinkValid,
    isLoading,
    errors,
    ...restProps
  } = props
  const { getIntlMessage } = useIntlHelper()
  const dashboardName = watch("dashboardName")
  const linkField = watch("link")

  useEffect(() => {
    if (open) {
      reset()
    }

    if (isEditMode) {
      setValue('dashboardName', dashboardInfo?.name)
      setValue('link', dashboardInfo?.link)
    }
  },
    // eslint-disable-next-line
    [isEditMode, reset, open])

  return (
    <Dialog
      {...restProps}
      fullWidth
      open={open}
      className='manageDashboard-dialog-container'
      classes={{ paper: 'paper-container' }}
    >
      <DialogTitle>
        {getIntlMessage(isEditMode ? 'edit_external_dashboard' : "external_dashboard")}
      </DialogTitle>

      <form onSubmit={handleSubmit(onApply)}>
        <DialogContent>
          <ReactIf value={isEditMode}>
            <DashboardNameComponent register={register} watch={watch} />
          </ReactIf>

          <div>
            <OutlinedInput
              required
              label="link"
              register={register("link")}
            />
            <ReactIf value={!isLinkValid || (errors?.link || [])[0]}>
              <p className="error-message-below-input">
                {(errors?.link || [])[0] || getIntlMessage("link_not_valid")}
              </p>
            </ReactIf>
          </div>

        </DialogContent>

        <DialogActions  >
          <Button onClick={props.onClose} variant='outlined'>
            {getIntlMessage("cancel")}
          </Button>

          <Button
            disabled={isLoading || isEditMode ? !dashboardName || !linkField : !linkField}
            startIcon={isLoading ? <img className="loading-icon" src={btnLoader} alt="add icon" /> : null}
            type="submit"
          >
            {getIntlMessage("add")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ManageExternalLink