import React, { useState, ReactNode } from 'react';
import * as Sentry from '@sentry/react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  const handleCatchError = (error: Error) => {
    setHasError(true);
    Sentry.captureException(error);
  };

  if (hasError) {
    return <h1>Something went wrong.</h1>;
  }

  return (
    <React.Fragment>
      {
        process.env.NODE_ENV === 'production' ? (
          React.Children.map(children, child => {
            return React.cloneElement(child as React.ReactElement<any>, {
              onError: handleCatchError
            });
          })
        ) : (
          children
        )
      }

    </React.Fragment>
  );
};

export default ErrorBoundary;