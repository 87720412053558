import React, { useMemo } from 'react'

import { addLogo } from 'assets/icons'
import { MaybeString } from 'types/general'

import './styles.scss'

type LogoAreaProps = {
  logoData?: {
    logo_cropped?: MaybeString
    logo_original?: MaybeString
  }
  onLogoAction: () => void
}

const LogoArea: React.FC<LogoAreaProps> = ({
  logoData,
  onLogoAction,
}) => {
  const companyLogo = useMemo(() => {
    if (logoData?.logo_cropped) {
      return logoData.logo_cropped
    }
    return addLogo

  }, [logoData])

  return (
    <div className='logo-wrapper' onClick={onLogoAction}>
      <img src={companyLogo} alt="add logo" className='logo-area' />

      <div>
        <p className='action'>{logoData?.logo_cropped ? "Edit Logo" : 'Upload Logo'}</p>
        <p className='logo-type'>jpg, jpeg, png (max 5 mb) </p>
      </div>
    </div>
  )
}

export default LogoArea