export function validatePassword(password: string = '', confirmPassword: string = '') {
  const minLength = 8;
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const digitRegex = /[0-9]/;
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

  const validBy: any = {
    length: false,
    upperCase: false,
    lowerCase: false,
    digit: false,
    specChar: false,
    isEquals: password === confirmPassword,
    fullyValid: false
  };

  if (password.length >= minLength) {
    validBy.length = true
  }

  if (uppercaseRegex.test(password)) {
    validBy.upperCase = true
  }

  if (lowercaseRegex.test(password)) {
    validBy.lowerCase = true
  }

  if (digitRegex.test(password)) {
    validBy.digit = true
  }

  if (specialCharRegex.test(password)) {
    validBy.specChar = true
  }

  const allKeysValid = Object.keys(validBy)
    .filter(key => key !== 'fullyValid')
    .every(key => validBy[key]);

  if (allKeysValid) {
    validBy.fullyValid = true;
  }

  return validBy;
}