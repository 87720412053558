import { createApi } from "@reduxjs/toolkit/query/react";
import { FieldValues } from "react-hook-form";

import baseQueryWithReauth from 'services/baseQueryWithReauth'

export const adminApi = createApi({
  reducerPath: "adminApi",
  keepUnusedDataFor: 1,
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    createCompany: build.mutation<any, FieldValues>({
      query: (data) => {
        return {
          url: `companies`,
          method: "POST",
          body: data,
        };
      },
    }),
    checkDBConnection: build.mutation<any, FieldValues>({
      query: (data) => {
        return {
          url: `companies/check-db-connection`,
          method: "POST",
          body: data,
        };
      },
    }),
    getAdminsList: build.query<any, any>({
      query: ({ pageSize, search, pageIndex, descending, sortBy, filterBy }: any) => {
        const filtering = filterBy.map((item: string) => `&user_status=${item}`).join("")
        return {
          url: `users?page=${pageIndex}&size=${pageSize}&query_str=${search}&descending=${descending}&sort_by=${sortBy}${filtering}`,
          method: "GET",
        };
      },
    }),
    getCompaniesList: build.query<any, any>({
      query: ({ pageSize, search, pageIndex, descending, sortBy, filterBy }: any) => {
        const filtering = filterBy.map((item: string) => `&company_status=${item}`).join("")
        return {
          url: `companies?page=${pageIndex}&size=${pageSize}&query_str=${search}&descending=${descending}&sort_by=${sortBy}${filtering}`,
          method: "GET",
        };
      }
    }),
  }),
});


export const { useCreateCompanyMutation, useGetAdminsListQuery, useGetCompaniesListQuery, useCheckDBConnectionMutation } = adminApi;
