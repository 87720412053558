import { useEffect, useState } from 'react'
import { useForm, } from "react-hook-form";
import clsx from 'clsx'
import dayjs from 'dayjs';

import Dialog, { DialogProps } from 'components/UI/Dialog'
import { ReactIf } from 'components/common/ReactIf'
import { DetailedCompanyData, LogoData } from 'types'

import CompanyInfoStep from './CompanyInfoStep'
import DatabaseInfoStep from './DatabaseInfoStep'
import { CompanyData } from './useManageCompanyDialog'

import './styles.scss'

type ManageCompanyDialogProps = DialogProps & {
  onClose: () => void
  onApply: (v: any) => void
  setLogoData: (v: LogoData | {}) => void
  isEditMode: boolean,
  manageProgress: boolean
  dialogType: number
  logoData: LogoData | {}
  companyData: CompanyData
}


const ManageCompanyDialog = (props: ManageCompanyDialogProps) => {
  const [step, setStep] = useState(1)

  const { handleSubmit, register, control, watch, setValue, getValues } = useForm({
    mode: 'onChange'
  });

  const {
    onApply,
    setLogoData,
    logoData,
    companyData,
    isEditMode,
    dialogType,
    onClose,
    manageProgress,
    ...restProps
  } = props

  const handleClose = () => {
    if (manageProgress) {
      return;
    }
    onClose();
  };

  useEffect(() => {
    if (isEditMode) {
      const data: DetailedCompanyData = companyData as DetailedCompanyData
      setValue('name', data?.name || '')
      setValue('main_contact_full_name', data?.main_contact_full_name || '')
      setValue('start_date', data?.start_date ? dayjs(data?.start_date) : null)
      setValue('add_on', data?.add_on || [])
      setValue('module', data?.module || [])
      setValue('status', data?.status || 'active')

      setValue('db_name', data?.db_name || '')
      setValue('db_username', data?.db_username || '')
      setValue('db_host', data?.db_host || '')
      setValue('db_port', data?.db_port || '')
    }
  }, [isEditMode, setValue, companyData])

  return (
    <Dialog
      {...restProps}
      onClose={handleClose}
      className={clsx('manageCompany-dialog-container')}
      classes={{ paper: 'paper-container' }}
    >
      <form onSubmit={handleSubmit(onApply)}>
        <ReactIf value={!dialogType || dialogType === 1}>
          <CompanyInfoStep
            onClose={props.onClose}
            onApply={() => setStep(step + 1)}
            btnName={isEditMode ? "save" : "next"}
            {...(isEditMode ? { btnType: 'submit' } : {})}
            register={register}
            watch={watch}
            control={control}
            manageProgress={manageProgress}
            setValue={setValue}
            getValues={getValues}
            logoData={logoData}
            setLogoData={setLogoData}
            isEditMode={isEditMode}
            dialogClassName={(step === 1 || dialogType === 1) ? "" : "hidden-step"}
          />
        </ReactIf>

        <ReactIf value={!dialogType || dialogType === 2}>
          <DatabaseInfoStep
            onClose={() => {
              if (dialogType === 2) {
                props.onClose()
              } else {
                setStep(step - 1)
              }
            }}
            btnType="submit"
            btnCloseName={isEditMode ? "close" : "back"}
            btnName={isEditMode ? "save" : "create"}
            isEditMode={isEditMode}
            register={register}
            manageProgress={manageProgress}
            watch={watch}
            dialogClassName={(step === 2 || dialogType === 2) ? "" : "hidden-step"}
          />
        </ReactIf>

      </form>
    </Dialog>
  )
}

export default ManageCompanyDialog