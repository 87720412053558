import { useEffect } from "react";
import { useForm } from "react-hook-form";

import Dialog, { DialogProps } from 'components/UI/Dialog'
import DialogTitle from 'components/UI/DialogTitle'
import DialogContent from 'components/UI/DialogContent'
import DialogActions from 'components/UI/DialogActions'
import Button from 'components/UI/Button'
import OutlinedInput from "components/UI/OutlinedInput";
import useIntlHelper from "hooks/useIntlHelper";
import { IFormikValues } from 'interfaces'

import './styles.scss'

type AddDashboardDialogProps = DialogProps & {
  onClose: () => void
  onApply: (v: IFormikValues) => void
}

const AddDashboardDialog = (props: AddDashboardDialogProps) => {
  const { register, handleSubmit, watch, reset } = useForm();
  const {
    onApply,
    open,
    ...restProps
  } = props
  const { getIntlMessage } = useIntlHelper()
  const dashboardName = watch("label")

  useEffect(() => {
    if (open) {
      reset()
    }
  }, [reset, open])

  return (
    <Dialog
      {...restProps}
      fullWidth
      open={open}
      className='addDashboard-dialog-container'
      classes={{ paper: 'paper-container' }}
    >
      <DialogTitle>
        {getIntlMessage('new_dashboard')}
      </DialogTitle>

      <form onSubmit={handleSubmit(onApply)}>
        <DialogContent>
          <div>
            <OutlinedInput
              required
              inputProps={{ maxLength: 40 }}
              register={register("label")}
            />
            <p className="max-length-area">
              {(dashboardName || '').length || 0}/40
            </p>
          </div>

          {/* <div className='checkbox-area'>
            <label>
              <Checkbox {...register('is_hidden')} defaultChecked={false} />
              Keep it Hidden
            </label>
          </div> */}

        </DialogContent>

        <DialogActions  >
          <Button onClick={props.onClose} variant='outlined'>
            {getIntlMessage("cancel")}
          </Button>

          <Button type="submit">
            {getIntlMessage("create")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddDashboardDialog