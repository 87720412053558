import { useState, useMemo } from 'react'
import { useSelector } from 'react-redux';

import { useChangeStatusDialog } from 'components/common/ChangeUserStatusDialog'
import { selectUserData } from '@redux/reducers/user.slice'
import { useGetCompaniesListQuery } from "services/admin";
import { isEmpty } from 'helpers';
import { Company, Filtering } from 'types'

export type UsePageLogicOptions = {
  filters: Filtering
  setFilters: (v: Filtering) => void
  isListEmpty: boolean
  pages: number
  onChangeStatus: (v: Company, s: string) => void
  refetch: () => Promise<any>
  companyList: Array<Company>
  changeStatusDialog: any,
  isUserDataLoading: boolean
}

const usePageLogic = (): UsePageLogicOptions => {
  const [filters, setFilters] = useState({
    search: "",
    filterBy: [],
    pageSize: 10,
    pageIndex: 1,
    descending: true,
    sortBy: "created_at",
  })

  const { isLoading: isUserDataLoading, } = useSelector(selectUserData);
  const { data = {}, refetch } = useGetCompaniesListQuery(filters)

  const { items: companyList = [], pages } = data

  const changeStatusDialog = useChangeStatusDialog(refetch, 'company')

  const isListEmpty = useMemo(() => isEmpty(companyList) && (isEmpty(filters.search) && isEmpty(filters.filterBy)), [filters, companyList])

  const onChangeStatus = (data: Company, newStatus: string) => {
    if (data.status !== newStatus) {
      changeStatusDialog.setOpen(true, data, newStatus)
    }
  }

  return {
    filters,
    //@ts-ignore
    setFilters,
    isListEmpty,
    pages,
    onChangeStatus,
    refetch,
    companyList,
    changeStatusDialog,
    isUserDataLoading,
  }
}

export default usePageLogic