import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectCompanyData } from '@redux/reducers/companyData'
import { useAvatarCropDialog } from 'components/common/AvatarCropDialog'
import { useGetCompanyInfoMutation, useEditCompanyInfoMutation } from 'services/company'
import { useChangeStatusDialog } from 'components/common/ChangeUserStatusDialog'
import { useManageCompanyDialog } from 'pages/AdminSide/Companies/ManageCompanyDialog'
import { statusList, toastDefaultSettings } from 'invariants'
import { modifyS3Url, prepareFieldsForEditLogo } from 'helpers'
import { LogoData } from "types";

const usePageLogic = () => {
  const params = useParams()
  const [fetchCompanyData]: any = useGetCompanyInfoMutation()
  const [editCompanyData]: any = useEditCompanyInfoMutation()
  const { data = {} }: any = useSelector(selectCompanyData);

  const refreshCompanyData = () => fetchCompanyData({ companyId: params.id })

  const manageCompanyDialog = useManageCompanyDialog({
    refreshData: refreshCompanyData,
    isEditMode: true
  })

  const changeStatusDialog = useChangeStatusDialog(refreshCompanyData, 'company')

  const saveLogosData = (result: LogoData) => {
    const {
      db_name,
      db_username,
      db_host,
      db_port,
      name,
      main_contact_full_name,
      start_date,
      add_on,
      module,
      status,
    } = data

    const logoData = prepareFieldsForEditLogo({
      ...result,
      logo_original: result?.logo_original === modifyS3Url(data?.logo_original_url) ? data?.logo_original_url : result?.logo_original,
    })

    const values = {
      ...logoData,
      db_name,
      db_username,
      db_host,
      db_port,
      name,
      main_contact_full_name,
      start_date,
      add_on,
      module,
      status,
    }

    editCompanyData({
      data: values,
      companyId: params.id
    })
      .then((resp: any) => {
        if (!resp.hasOwnProperty('error')) {
          refreshCompanyData()
          toast.warning("Company logo was updated successfully", toastDefaultSettings)
        }
      })
  }

  const avatarCropDialog = useAvatarCropDialog(saveLogosData)

  const onFileChange = (e: any) => {
    const file = e.target.files[0];

    if (!file) {
      return
    }
    // Check file size
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
    if (file.size > maxSizeInBytes) {
      toast.warning('File size exceeds 5MB limit.', toastDefaultSettings)
      return;
    }

    // Check file format
    const allowedFormats = ["jpeg", "png", "jpg"];
    const extension = file.name.split(".").pop()?.toLowerCase();
    if (!extension || !allowedFormats.includes(extension)) {
      toast.warning("Invalid file format. Only JPEG, PNG, and JPG formats are allowed.", toastDefaultSettings)
      return;
    }

    const reader: any = new FileReader();

    reader.onload = () => {
      //@ts-ignore
      avatarCropDialog.setOpen(true, reader.result);
      e.target.value = null
    };
    reader.readAsDataURL(file);
  };

  const onStatusChange = (newStatus: string) => {
    if (data.status !== newStatus) {
      changeStatusDialog.setOpen(true, data, newStatus)
    }
  }

  return {
    onFileChange,
    changeStatusDialog,
    avatarCropDialog,
    manageCompanyDialog,
    companyData: data,
    onStatusChange,
    statusesList: Object.values(statusList)
  }
}
export default usePageLogic