import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';

import { useLogoutUserMutation } from 'services/user/user';
import { selectUserData } from '@redux/reducers/user.slice'
import { getValueFromLS, clearTokens } from 'helpers'
import { routes, tokens } from 'invariants'

import useAddDashboard from '../AddDashboard'

const usePageLogic = () => {
  const { dashboardId, id: companyId } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const addDashboard = useAddDashboard()

  const [openLogoutDialog, setOpenLogoutDialog] = useState<boolean>(false)

  const { data = {} } = useSelector(selectUserData);

  const { full_name } = data

  const [logoutUser,] = useLogoutUserMutation();

  const logout = () => {
    logoutUser({
      refresh_token: getValueFromLS(tokens.refreshToken)
    })
      .then(() => {
        clearTokens()
        dispatch({ type: 'RESET' });
        navigate(routes.signIn);
      })
  }

  return {
    companyId,
    dashboardId,
    setOpenLogoutDialog,
    userName: full_name,
    logout,
    openLogoutDialog,
    addDashboard,
  }
}

export default usePageLogic