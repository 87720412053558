
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';

import { selectUserData } from '@redux/reducers/user.slice'
import NavigationTabs from 'components/common/NavigationTabs'
import useIntlHelper from 'hooks/useIntlHelper'
import { logoBlue, logoutIcon } from 'assets/icons'
import { useLogoutUserMutation } from 'services/user/user';
import { getValueFromLS, clearTokens } from 'helpers'
import { routes, tokens } from 'invariants'

import ActionDialog from '../ActionDialog';

import './styles.scss'

function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { getIntlMessage } = useIntlHelper()

  const [open, setOpen] = useState<boolean>(false)

  const { data = {} } = useSelector(selectUserData);

  const { full_name, role } = data
  const [logoutUser,] = useLogoutUserMutation();

  const logout = () => {
    logoutUser({
      refresh_token: getValueFromLS(tokens.refreshToken)
    })
      .then(() => {
        clearTokens()
        dispatch({ type: 'RESET' });
        navigate(routes.signIn);
      })
  }

  return (
    <header className='admin-header'>
      <div className='naw-wrapper'>
        <NavLink to="/admin/companies">
          <img className='company-dark-logo' src={logoBlue} alt="logo" />
        </NavLink>

        <div className='header-tabs-wrapper'>
          <NavigationTabs
            navigationList={[
              {
                path: routes.adminCompanies,
                label: 'Companies'
              },
              {
                path: routes.adminManagement,
                label: ' Admin Management'
              },
            ]}
          />
        </div>
      </div>

      <div className='header-info'>
        <div>
          <p className='adminName'>{full_name}</p>
          <p className='adminRole'>{getIntlMessage(role)}</p>
        </div>
        <IconButton aria-label="logout" onClick={() => setOpen(true)}
          className='logout-icon'>
          <img
            src={logoutIcon}
            alt="logout"
          />
        </IconButton>
      </div>

      <ActionDialog
        onClose={() => setOpen(false)}
        onApply={logout}
        open={open}
        ids={{
          dialogTitle: "logOut",
          dialogContent: "confirmLogOut"
        }}
      />
    </header>
  );
}

export default Header;