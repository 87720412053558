import { createSlice } from '@reduxjs/toolkit';

const dashboardDataSlice = createSlice({
  name: 'dashboardInfo',
  initialState: {
    data: {},
    isLoading: true,
    error: null,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    getDashboardDataSuccess(state, action) {
      state.data = action.payload;
      state.isLoading = false
      state.isSuccess = true
    },
    getDashboardDataFailure(state, action) {
      state.error = action.payload;
      state.isLoading = false
      state.isError = true
    },
  },
});

export const { getDashboardDataSuccess, getDashboardDataFailure } = dashboardDataSlice.actions;

export const selectDashboardData = (state: any) => state.dashboardData

export default dashboardDataSlice.reducer;