import {
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import {
  BaseQueryFn,
  QueryReturnValue,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import baseApi from './baseApi'

const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result: MaybePromise<
    QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
  > = await baseApi(args, api, extraOptions);

  const isSuccess = !result.hasOwnProperty('error')

  //@ts-ignore
  if (args?.onError && !isSuccess) {
    //@ts-ignore
    api.dispatch(args.onError(result?.error || {}))
  }

  //@ts-ignore
  if (args?.onSuccess && isSuccess) {
    //@ts-ignore
    api.dispatch(args.onSuccess(result.data))
  }
  return result;
};

export default baseQuery