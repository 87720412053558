import { useState } from "react"
import { toast } from "react-toastify";

import { useRemoveUserMutation } from "services/user/user";
import useIntlHelper from 'hooks/useIntlHelper'
import { User } from 'types/user'
import { toastDefaultSettings } from 'invariants'

import DeleteUserDialog from "./DeleteUserDialog"

type dialogCD = () => void

export const useDeleteUserDialog = (refreshAfterAction: dialogCD, type: string) => {
  const [isOpen, setOpen] = useState(false)
  const [rowData, setRowData] = useState<User>({} as User)

  const { getIntlMessage } = useIntlHelper()
  const [removeUser] = useRemoveUserMutation()

  const handleSetOpen = (open: boolean, row: User,) => {
    setOpen(open)
    setRowData(row)
  }

  const onApply = () => {
    removeUser({ userId: rowData?.id })
      .then((resp: any) => {
        if (!resp.hasOwnProperty('error')) {
          refreshAfterAction()
          toast.success(getIntlMessage(`successfully_message_after_remove_${type}`), toastDefaultSettings)
          setOpen(false)
        }
      })
  }

  return {
    el: (
      <DeleteUserDialog
        open={isOpen}
        onClose={() => setOpen(false)}
        onApply={onApply}
        type={type}
      />
    ),
    setOpen: handleSetOpen
  }
}
