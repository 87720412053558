import React from "react"
import { useSelector } from 'react-redux';

import { selectUserData } from '@redux/reducers/user.slice'
import { ReactIf } from 'components/common/ReactIf'

import Header from '../AdminHeader'

type LayoutContentProps = {
  children: React.ReactNode
}

const LayoutContent: React.FC<LayoutContentProps> = ({ children }) => {
  const { isLoading: isUserDataLoading, isSuccess, isError } = useSelector(selectUserData);

  const isCompleted = !isUserDataLoading && (isSuccess || isError)

  return (
    <React.Fragment>
      <Header />
      <ReactIf value={isCompleted}>
        {children}
      </ReactIf>
    </React.Fragment>
  )
}

export default LayoutContent