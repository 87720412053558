import { ReactNode } from 'react';
import MuiDialogTitle, { DialogTitleOwnProps } from '@mui/material/DialogTitle';
import clsx from 'clsx'

import './styles.scss'

export type DialogTitleProps = DialogTitleOwnProps & {
  children: ReactNode,
  className?: string
}

const DialogTitle: React.FC<DialogTitleProps> = ({
  className = '',
  children
}) => {
  return (
    <MuiDialogTitle className={clsx('dialog-title-wrapper', className)}>
      {children}
    </MuiDialogTitle>
  )
}

export default DialogTitle