export const getModuleDescription = (data: any) => {
  const res = []

  if (data.is_inventory_optimization) {
    res.push('Inventory Optimization')
  }

  if (data.is_price_optimization) {
    res.push('Price Optimization')
  }

  if (!res.length) {
    return '-'
  }

  return res.join(', ')
}

export const modifyS3Url = (url: string) => {
  if (!url) {
    return null
  }

  const prefix = "https://s3.us-east-2.amazonaws.com/";
  if (url.startsWith("https://")) {
    url = url.substring("https://".length);
  }

  return prefix + url;
}

export const prepareFieldsForEditLogo = ({
  logo_cropped = '',
  logo_original,
}: any) => {
  const obj: any = {}

  if (logo_cropped && logo_cropped.startsWith("data:image")) {
    obj.logo_cropped = logo_cropped
  } else if (!logo_cropped) {
    obj.logo_cropped_url = null
  } else {
    obj.logo_cropped_url = logo_cropped
  }

  if (logo_original && logo_original.startsWith("data:image")) {
    obj.logo_original = logo_original
  } else if (!logo_original) {
    obj.logo_original_url = null
  } else {
    obj.logo_original_url = logo_original
  }

  return obj
}

export const filterObjectByFields = (obj: any, fields: Array<string>) => {
  const filteredObj: any = {};
  fields.forEach((field: any) => {
    if (obj.hasOwnProperty(field)) {
      filteredObj[field] = obj[field];
    }
  });
  return filteredObj;
}

export const isEqual = (a: any, b: any) => {
  return a === b;
} 