import SetPassword from "./SetPassword"

const RestorePassword = () => {
  return (
    <SetPassword
      isNewUser={false}
    />
  )
}

export default RestorePassword