import { useRef } from 'react'
import { IconButton } from '@mui/material'
import { NavLink } from 'react-router-dom';

import Button from "components/UI/Button";
import LogoArea from "components/common/LogoArea";
import AddOn from 'components/common/AddOn'
import Status from 'components/UI/Status'
import { edit } from 'assets/icons'
import { formatDate, modifyS3Url } from 'helpers'
import { useIntlHelper } from 'hooks'

import usePageLogic from "./usePageLogic";

import './styles.scss'

const Profile = () => {
  const inputRef = useRef(null);
  const { getIntlMessage } = useIntlHelper()

  const { onFileChange, statusesList, avatarCropDialog, manageCompanyDialog, changeStatusDialog, companyData, onStatusChange } = usePageLogic()

  const handleButtonClick = () => {
    if (companyData?.logo_original_url) {
      const res: any = modifyS3Url(companyData?.logo_original_url as string)

      avatarCropDialog.setOpen(true, res);

      return
    }

    if (inputRef?.current) {
      //@ts-ignore
      inputRef.current.click();
    }
  };



  return (
    <div className="detailed-company-profile">
      <div className="company-title">
        <h2>
          {companyData?.name}
        </h2>

        <NavLink to={`/user/company/${companyData?.id}`}>
          <Button>Go to Client Workspace</Button>
        </NavLink>
      </div>

      <div className="data-container">
        <div className='company-block'>
          <input
            type="file"
            accept="image/*"
            onChange={onFileChange}
            ref={inputRef}
            style={{ display: 'none' }}
          />
          <LogoArea
            logoData={{
              logo_cropped: companyData?.logo_cropped_url
            }}
            onLogoAction={handleButtonClick} />
        </div>

        <div className='company-block'>
          <h3 className='block-title'>
            Company info
            <IconButton className='edit-company-data' onClick={() => manageCompanyDialog.setOpen(true, companyData, 1)}>
              <img src={edit} alt='edit company info' />
            </IconButton>
          </h3>

          <ul>
            <li className='category-wrapper'>
              <span className='category-desc'> Status</span>
              <span className='category-value'><Status value={companyData?.status} statusList={statusesList} onChange={onStatusChange} /></span>
            </li>

            <li className='category-wrapper'>
              <span className='category-desc'>Main contact ful name</span> <span className='category-value'>{companyData?.main_contact_full_name}</span>
            </li>

            <li className='category-wrapper'>
              <span className='category-desc'>Module</span> <span className='category-value'>
                <AddOn addOn={companyData?.module || []} />
              </span>
            </li>

            <li className='category-wrapper'>
              <span className='category-desc'>Add on</span>{" "}
              <span className='category-value'>
                <AddOn addOn={companyData?.add_on || []} />
              </span>
            </li>

            <li className='category-wrapper'>
              <span className='category-desc'>Start date</span> <span className='category-value'>{formatDate(companyData?.start_date) || '-'}</span>
            </li>
          </ul>
        </div>

        <div className='company-block'>
          <h3 className='block-title'>
            Database info
            <IconButton className='edit-company-data' onClick={() => manageCompanyDialog.setOpen(true, companyData, 2)}>
              <img src={edit} alt='edit database info' />
            </IconButton>
          </h3>

          <ul>
            <li className='category-wrapper'>
              <span className='category-desc'>{getIntlMessage("nameField")}</span> <span className='category-value'>{companyData?.db_name || "-"}</span>
            </li>
            <li className='category-wrapper'>
              <span className='category-desc'>{getIntlMessage("usernameField")}</span> <span className='category-value'>{companyData?.db_username || "-"}</span>
            </li>
            <li className='category-wrapper'>
              <span className='category-desc'>{getIntlMessage("authPassword")}</span> <span className='category-value'>{companyData?.db_username ? "******" : "-"}</span>
            </li>
            <li className='category-wrapper'>
              <span className='category-desc'>{getIntlMessage("hostField")}</span> <span className='category-value'>{companyData?.db_host || "-"}</span>
            </li>
            <li className='category-wrapper'>
              <span className='category-desc'>{getIntlMessage("portField")}</span> <span className='category-value'>{companyData?.db_port || "-"}</span>
            </li>
          </ul>
        </div>
      </div>
      {avatarCropDialog.el}
      {changeStatusDialog.el}
      {manageCompanyDialog.el}
    </div >
  )
}

export default Profile