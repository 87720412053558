import { useState, useMemo } from "react";
import { Menu, MenuItem, IconButton } from "@mui/material";
import clsx from "clsx";

import { iSortAsc, iSortDesc } from "assets/icons";
import { Filtering } from 'types'

import "./styles.scss";

type TableSortingProps = {
  id: string;
  label: string;
  filters: Filtering
  setFilters: (v: Filtering) => void;
  defaultSorting: {
    [key: string]: any;
  };
};

const TableSorting: React.FC<TableSortingProps> = ({
  label,
  id,
  filters,
  setFilters,
  defaultSorting = {},
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const sortingIcon = useMemo(() => {
    if (filters.sortBy !== id) {
      return iSortAsc;
    }

    return filters.descending ? iSortDesc : iSortAsc;
  }, [id, filters]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSortAndClose = (isDesc: boolean) => {
    let values = {};

    if (filters?.sortBy === id && filters?.descending === isDesc) {
      values = {
        ...filters,
        ...defaultSorting,
      };
    } else {
      values = {
        ...filters,
        descending: isDesc,
        sortBy: id,
      };
    }

    setFilters(values as Filtering);

    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls={open ? "basic-menu" : undefined}
        onClick={handleClick}
      >
        <img
          className={clsx({
            isSortingEmpty: filters.sortBy !== id,
          })}
          src={sortingIcon}
          alt={`sort by ${label}`}
        />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="sorting-container"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div className="sort-group">
          <div className="category-title">Sorting</div>
          <MenuItem
            onClick={() => handleSortAndClose(false)}
            className={clsx({
              "active-sorting": !filters?.descending && filters?.sortBy === id,
            })}
          >
            <img
              src={iSortAsc}
              className="sort-icon"
              alt="sorting-by Ascending"
            />{" "}
            Ascending
          </MenuItem>
          <MenuItem
            onClick={() => handleSortAndClose(true)}
            className={clsx({
              "active-sorting": filters?.descending && filters?.sortBy === id,
            })}
          >
            <img
              src={iSortDesc}
              className="sort-icon"
              alt="sorting-by Descending"
            />{" "}
            Descending
          </MenuItem>
        </div>
      </Menu>
    </>
  );
};

export default TableSorting;
