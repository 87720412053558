import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import clsx from 'clsx'
import { Tooltip } from "@mui/material";

import OutlinedInput from "components/UI/OutlinedInput";
import Button from "components/UI/Button";
import { check, checkValid } from 'assets/icons'
import { validatePassword } from 'helpers'

import AuthModule from '../Auth'
import { usePageLogic } from "./usePageLogic";

import "./styles.scss";

type SetPasswordProps = {
  isNewUser: boolean
}

const SetPassword: React.FC<SetPasswordProps> = ({ isNewUser }) => {
  const { register, handleSubmit, watch } = useForm();
  const { formHandler, isError, errors } = usePageLogic(isNewUser);

  const password = watch('password');
  const confirmPassword = watch('confirm_password');

  const validBy: any = useMemo(() => validatePassword(password, confirmPassword), [password, confirmPassword])

  const tooltipMessage = useMemo(() => {
    if (!validBy.length || !validBy.upperCase || !validBy.lowerCase || !validBy.digit || !validBy.specChar) {
      return 'Password rules is not completed'
    }

    if (!validBy.isEquals) {
      return `Password doesn't match`
    }
    return ''
  }, [validBy])

  return (
    <AuthModule>
      <section className="set-password-page">
        <h3 className="auth-title main-text-color mb-12">
          <FormattedMessage id={isNewUser ? "setPassword" : "setupNewPassword"} />
        </h3>

        {
          isError && (
            <div className="error-message-block">
              {errors[0]}
            </div>
          )
        }

        <form onSubmit={handleSubmit(formHandler)}>

          <OutlinedInput
            showPasswordLogic
            placeholder="enterPassword"
            label={isNewUser ? "authPassword" : "newPassword"}
            type="password"
            required={true}
            register={register("password")}
          />
          <OutlinedInput
            showPasswordLogic
            placeholder="enterPassword"
            label="confirmPassword"
            type="password"
            required={true}
            register={register("confirm_password")}
          />

          <div className="validation-rules">
            <ol>
              <li className={clsx("validation-rule", {
                "valid-by": validBy.length
              })} >
                <img src={validBy.length ? checkValid : check} alt="valid by length" /> <span>At least 8 characters</span>
              </li>

              <li className={clsx("validation-rule", {
                "valid-by": validBy.upperCase
              })} >
                <img src={validBy.upperCase ? checkValid : check} alt="valid by length uppercase letter" /> At least 1 uppercase letter (A-Z)
              </li>
              <li className={clsx("validation-rule", {
                "valid-by": validBy.lowerCase
              })} >
                <img src={validBy.lowerCase ? checkValid : check} alt="valid by length lowercase letter" /> At least 1 lowercase letter (a-z)
              </li>
              <li className={clsx("validation-rule", {
                "valid-by": validBy.digit
              })} >
                <img src={validBy.digit ? checkValid : check} alt="valid by digit" />At least  1 digit (0-9)
              </li>

              <li className={clsx("validation-rule", {
                "valid-by": validBy.specChar
              })} >
                <img src={validBy.specChar ? checkValid : check} alt="valid by special character" /> At least  1 special character (!, @, #, $, etc.)</li>
            </ol>
          </div>

          <Tooltip title={tooltipMessage}>
            <div>
              <Button fullWidth
                type="submit"
                disabled={!validBy.fullyValid}
              >
                <FormattedMessage id={isNewUser ? "createAnAccount" : "savePassword"} />
              </Button>
            </div>
          </Tooltip>


        </form>
      </section>
    </AuthModule>
  );
};

export default SetPassword;
