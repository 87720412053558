
import { useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import LayoutContent from 'components/common/LayoutContent'
import NavigationTabs from 'components/common/NavigationTabs'
import { ReactIf } from 'components/common/ReactIf'
import AdminPageSubheader from 'components/common/AdminPageSubheader'
import { useGetCompanyInfoMutation } from "services/company";
import { selectCompanyData } from '@redux/reducers/companyData'
import { routes } from 'invariants'

import './styles.scss'

type DetailedCompanyProps = {
  children: React.ReactNode
}

const DetailedCompany: React.FC<DetailedCompanyProps> = ({ children }) => {
  const navigate = useNavigate()
  const params = useParams()
  const { data = {} } = useSelector(selectCompanyData);

  const [fetchData, { isSuccess }] = useGetCompanyInfoMutation()

  useEffect(() => {
    fetchData({ companyId: params.id })
  }, [fetchData, params.id])

  return (
    <LayoutContent>
      <ReactIf value={isSuccess}>
        <AdminPageSubheader
          title={data?.name}
          withIcon
          onClick={() => navigate(routes.adminCompanies)}
        />
        <div className='detailed-page-container'>
          <NavigationTabs
            navigationList={[
              {
                path: `/admin/company/${params.id}/profile`,
                label: 'Company Profile'
              },
              {
                path: `/admin/company/${params.id}/users`,
                label: 'Users'
              },
            ]}
          />

          <div className="data-wrapper">
            {children}
          </div>

        </div>
      </ReactIf>
    </LayoutContent>
  )
}

export default DetailedCompany