import React from 'react'

import { planningCycle } from 'assets/icons'
import useIntlHelper from 'hooks/useIntlHelper'

import './styles.scss'

type EmptyTableStateProps = {
  title: string
  subTitle: string
}

const EmptyTableState: React.FC<EmptyTableStateProps> = ({
  title,
  subTitle,
}) => {
  const { getIntlMessage } = useIntlHelper()

  return (
    <div className="table-empty-state">
      <img src={planningCycle} className='logo' alt="empty state for table" />

      <h2 className="title">{getIntlMessage(title)}</h2>

      <p className='subtitle'>{getIntlMessage(subTitle)}</p>

    </div>
  )
}

export default EmptyTableState