import React, { useState, KeyboardEvent } from 'react'
import MuiPagination, { PaginationProps as MuiPaginationProps } from '@mui/material/Pagination';
import clsx from 'clsx'

import OutlinedInput from 'components/UI/OutlinedInput';

import './styles.scss'

type PaginationProps = MuiPaginationProps & {
  page?: number
  count: number
  onChange: (v: any, pageIndex: number) => void
  position?: "center" | "left" | "right"
}

const Pagination: React.FC<PaginationProps> = ({
  onChange,
  page = 1,
  position = 'center',
  count,
  ...otherProps
}) => {
  const [value, setValue] = useState<string>('');

  const handleChange = (event: any) => {
    const newValue = event.target.value;

    if (parseInt(newValue) > count) {
      setValue(`${count}`);
    } else if (parseInt(newValue) <= 0) {
      setValue("1");
    } else {
      setValue(newValue);
    }
  };

  const handleBlur = () => {
    onChange(null, parseInt(value) || 1)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      (event.target as HTMLInputElement).blur();
    }
  };

  return (
    <div className='table-pag-container'>
      <div className={clsx('pag-position', {
        "position-center": position === "center",
        "position-left": position === "left",
        "position-right": position === "right",
      })}>
        <span className='pag-title'>Go to page</span>
        <OutlinedInput type="number"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />

        <MuiPagination
          page={page}
          onChange={onChange}
          className='pagination-wrapper'
          variant="outlined"
          shape="rounded"
          count={count}
          {...otherProps}
        />
      </div>

    </div>
  )
}

export default Pagination