import { useState, useContext } from "react"
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom'

import useIntlHelper from 'hooks/useIntlHelper'
import { toastDefaultSettings } from 'invariants'
import { useCreateDashboardMutation } from 'services/dashboard'
import { IFormikValues } from 'interfaces'

import Context from '../Context'
import AddDashboard from "./AddDashboard"


const useAddDashboardDialog = () => {
  const navigate = useNavigate()

  const [createNewDashboard] = useCreateDashboardMutation()
  const { companyId, getDashboards } = useContext(Context)
  const [isOpen, setOpen] = useState(false)
  const { getIntlMessage } = useIntlHelper()

  const handleSetOpen = (open: boolean) => {
    setOpen(open)
  }

  const handleCreate = (values: IFormikValues) => {
    createNewDashboard({
      data: {
        name: values.label,
        is_hidden: false
      },
      companyId
    }).then((resp: any) => {
      if (!resp.hasOwnProperty('error')) {
        getDashboards()
        toast.success(getIntlMessage(`successfully_message_after_change_create_dashboard`), toastDefaultSettings)
        handleClose()
        navigate(`/user/company/${companyId}/${resp.data.id}`)
      }
    })
  }

  const handleClose = () => {
    setOpen(false)
  }


  return {
    el: (
      <AddDashboard
        open={isOpen}
        onClose={handleClose}
        onApply={handleCreate}
      />
    ),
    setOpen: handleSetOpen
  }
}

export default useAddDashboardDialog