import { ReactNode } from 'react';
import MuiDialogContent, { DialogContentProps as MuiDialogContentProps } from '@mui/material/DialogContent';

import './styles.scss'

export type DialogTitleProps = MuiDialogContentProps & {
  children: ReactNode
}

const DialogContent: React.FC<DialogTitleProps> = ({
  children
}) => {
  return (
    <MuiDialogContent className='dialog-content-wrapper'>
      {children}
    </MuiDialogContent>
  )
}

export default DialogContent