import { ToastContainer, ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { iconSuccess } from 'assets/icons'

import './styles.scss'

export const ToastifyContainer: React.FC<ToastContainerProps> = (props) => {
  return (
    <ToastContainer
      icon={(
        <img src={iconSuccess} alt="toastify" />
      )}
      className="toastify-container"
      theme="dark"
      autoClose={false}
      hideProgressBar={true}
      closeOnClick={true}
      {...props}
    />
  )
}

export default ToastifyContainer