import { useCallback, useMemo, useState } from "react";
import clsx from 'clsx'
import MuiOutlinedInput, { OutlinedInputProps as MuiOutlinedInputProps } from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { IconButton } from "@mui/material";

import { ReactIf } from "components/common/ReactIf";
import useIntlHelper from "hooks/useIntlHelper";
import { isEmpty } from "helpers";
import { eye, eyeOff, attention } from 'assets/icons'

import "./styles.scss";

export type OutlinedInputProps = MuiOutlinedInputProps & {
  type?: string;
  withStar?: boolean;
  label?: string;
  showPasswordLogic?: boolean
  register?: any;
  errorMessage?: string
  hideIconWhenFieldIsEmpty?: boolean
  watch?: any
};

function OutlinedInput(props: OutlinedInputProps) {
  const [hidden, setHidden] = useState(true);

  const { getIntlMessage } = useIntlHelper()

  const {
    type = "string",
    name,
    label = "",
    className = "",
    placeholder = "",
    showPasswordLogic = false,
    hideIconWhenFieldIsEmpty = false,
    register = {},
    withStar,
    onChange,
    errorMessage = '',
    watch,
    ...otherProps
  } = props;

  const getType = () => {
    if (type !== 'password') {
      return type
    }

    return hidden ? "password" : "text"
  }

  const isVisible = useCallback(() => {
    if (hideIconWhenFieldIsEmpty && watch) {
      return !!(watch(register.name) || '').length
    }
    return true
  }, [hideIconWhenFieldIsEmpty, watch, register])

  const combinedChangeEvent = (e: any) => {
    if (register?.onChange) {
      register?.onChange(e)
    }
    if (onChange) {
      onChange(e)
    }
  }


  const passwordIcon = useMemo(() => (showPasswordLogic && isVisible() ? {
    endAdornment: (
      <IconButton onClick={() => setHidden(!hidden)} >
        <img src={hidden ? eye : eyeOff} className="endAdornment-icon" alt="eye" />
      </IconButton>
    )
  } : {}), [hidden, showPasswordLogic, isVisible])

  const errorIconProps = useMemo(() => (!isEmpty(errorMessage) ? {
    endAdornment: (
      <img src={attention} className="endAdornment-icon default-cursor" alt="attention-icon" />
    )
  } : {}), [errorMessage])


  return (
    <div className="mui-field-container">
      <ReactIf value={Boolean(label)}>
        <InputLabel shrink htmlFor={label} className="outlined-label neutral-500">
          {getIntlMessage(label)}

          {withStar && (
            <span className="label-star">*</span>
          )}
        </InputLabel>
      </ReactIf>

      <MuiOutlinedInput
        {...otherProps}
        {...register}
        onChange={combinedChangeEvent}
        className={clsx('mui-outlinedInput-override', className)}
        placeholder={getIntlMessage(placeholder)}
        classes={{
          error: "outlined-input-error",
        }}
        id={label}
        fullWidth
        type={getType()}
        {
        ...({ ...passwordIcon, ...errorIconProps, })
        }
      />
      <ReactIf value={!isEmpty(errorMessage)}>
        <p className="outlinedInput-error-message text-error-color">
          {errorMessage}
        </p>
      </ReactIf>

    </div>
  );
}

export default OutlinedInput;