import {
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import {
  BaseQueryFn,
  QueryReturnValue,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import baseApi from './baseApi'

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {

  let result: MaybePromise<
    QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
  > = await baseApi(args, api, extraOptions);

  //@ts-ignore
  if (result?.error?.status && args?.onError) {
    //@ts-ignore
    api.dispatch(args.onError(result?.error || {}))

  }

  //@ts-ignore
  if (result?.data && args?.onSuccess) {
    //@ts-ignore
    api.dispatch(args.onSuccess(result.data))
  }

  return result;
};

export default baseQueryWithReauth