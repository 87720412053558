import { useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useGetUsersListQuery } from "services/user/user";
import { isEmpty } from 'helpers';

const usePageLogic = (): any => {
  const { id: companyId } = useParams()
  const [filters, setFilters] = useState({
    search: "",
    filterBy: [],
    pageSize: 10,
    pageIndex: 1,
    descending: true,
    sortBy: "created_at",
  })

  const { data = {}, refetch } = useGetUsersListQuery({
    ...filters,
    companyId,
  })

  const { items: userList = [], pages } = data

  const isListEmpty = useMemo(() => isEmpty(userList) && (isEmpty(filters.search) && isEmpty(filters.filterBy)), [filters, userList])
  return {
    filters,
    setFilters,
    isListEmpty,
    pages,
    refetch,
    userList
  }
}

export default usePageLogic