import React from 'react'
import { IconButton } from '@mui/material'

import { closeWhite } from 'assets/icons'

import './style.scss'

const WidgetList: React.FC<any> = ({
  showBlock,
  onClose,
  blockRef,
}) => {
  return (
    <>
      {showBlock && (
        <div className="fixed-block" ref={blockRef}>
          <div className='widgets-list'>

          </div>
          <IconButton onClick={onClose} className='close-widgets-list'>
            <img src={closeWhite} alt="close widgets list" />
          </IconButton>
        </div>


      )}
    </>
  )
}

export default WidgetList