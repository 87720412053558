import { FormattedMessage } from "react-intl";

import LayoutContent from 'components/common/LayoutContent'
import AdminPageSubheader from 'components/common/AdminPageSubheader'
import EmptyState from 'components/common/EmptyState'
import { ReactIf } from 'components/common/ReactIf'
import Button from 'components/UI/Button'
import Search from 'components/common/Search'
import { plus, companies as emptyCompaniesLogo } from 'assets/icons'
import { useIntlHelper } from 'hooks'

import CompaniesTable from "./CompaniesTable";
import usePageLogic from './usePageLogic';
import { useManageCompanyDialog } from './ManageCompanyDialog'

import './styles.scss'

const Companies = () => {
  const {
    filters,
    setFilters,
    isListEmpty,
    pages,
    refetch,
    onChangeStatus,
    companyList,
    changeStatusDialog,
  } = usePageLogic()
  const manageCompanyDialog = useManageCompanyDialog({
    refreshData: refetch,
  })
  const { getIntlMessage } = useIntlHelper()

  return (
    <LayoutContent>
      <section className='admin-companies'>
        <AdminPageSubheader
          title={getIntlMessage('companies')}
        />

        <div className="admin-companies-content">
          <ReactIf value={!isListEmpty}>
            <div className='search-wrapper'>
              <Search
                onChange={(value: string) => {
                  setFilters({
                    ...filters,
                    pageIndex: 1,
                    search: value,
                  })
                }}
              />

              <Button
                startIcon={<img src={plus} alt="add icon" />}
                onClick={() => manageCompanyDialog.setOpen(true)}
              >
                <FormattedMessage id="newCompany" />
              </Button>
            </div>
          </ReactIf>

          {
            isListEmpty ? (
              <EmptyState
                emptyStateLogo={emptyCompaniesLogo}
                title="Companies will be shown here"
                btnTitle="newCompany"
                createAction={() => manageCompanyDialog.setOpen(true)}
              />
            ) : (
              <CompaniesTable
                data={companyList}
                pages={pages || 0}
                setFilters={setFilters}
                filters={filters}
                onChangeStatus={onChangeStatus}
              />
            )
          }

          {manageCompanyDialog.el}
          {changeStatusDialog.el}
        </div>
      </section>
    </LayoutContent>
  )
}

export default Companies