import { useIntl } from "react-intl";

const useIntlHelper = () => {
  const intl = useIntl();

  const getIntlMessage = (id: string) => {
    if (!id) {
      return ""
    }
    return intl.formatMessage({
      id,
      defaultMessage: "",
    });
  };

  return {
    getIntlMessage
  }
}

export default useIntlHelper