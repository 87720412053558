import React, { useEffect } from 'react';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { Controller } from 'react-hook-form';

import './styles.scss'

type ClearablePropProps = {
  control: any
  fieldName: string
  label: string
  defaultValue?: any,
  format?: string
  placeholder?: string
  clearable?: boolean
}

const ClearableProp: React.FC<ClearablePropProps> = ({
  control,
  fieldName,
  label,
  defaultValue = null,
  format = 'MM DD, YYYY',
  placeholder = " ",
  clearable = true,
}) => {
  const [cleared, setCleared] = React.useState<boolean>(false);

  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => { };
  }, [cleared]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        className="datepicker-wrapper"
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <DemoItem label={label}>
          <Controller
            name={fieldName}
            control={control}

            defaultValue={defaultValue}
            render={({ field }: any) => (
              <DatePicker
                {...field}
                format={format}
                slotProps={{
                  field: { clearable, onClear: () => setCleared(true) },
                  popper: { className: 'popper-datepicker' },
                  textField: { placeholder },
                  inputAdornment: {
                    position: 'start',
                  },
                }}
              />
            )}
          />
        </DemoItem>

        {cleared && (
          <Alert
            sx={{ position: 'absolute', bottom: 0, right: 0 }}
            severity="success"
          >
            Field cleared!
          </Alert>
        )}
      </Box>
    </LocalizationProvider>
  );
}

export default ClearableProp