
import React, { useRef, useMemo, RefObject } from 'react'
import AvatarGroup from '@mui/material/AvatarGroup';
import { Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';

import { AssociatedUser } from 'types/company'

import './styles.scss'

type AvatarListProps = {
  data: AssociatedUser[]
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 32,
      height: 32
    },
    children: name[0],
  };
}

const AvatarList: React.FC<AvatarListProps> = ({ data }) => {
  const avatarRef: RefObject<HTMLDivElement> = useRef(null)

  const maxElements = useMemo(() => {
    const { clientWidth = 150 } = avatarRef?.current || {};
    return Math.floor((clientWidth - 16) / 35)
  }, [avatarRef])

  return (
    <div ref={avatarRef} className='avatar-list-container'>
      <AvatarGroup max={maxElements}>
        {
          data.map(({ full_name, id }: AssociatedUser) => (
            <Tooltip key={id} title={full_name}>
              <Avatar
                {...stringAvatar(full_name || '-')}
              />
            </Tooltip>
          ))
        }
      </AvatarGroup>
    </div>

  )
}

export default AvatarList