import { useCallback, useState, useEffect } from 'react'
import clsx from 'clsx'
import { Tooltip } from '@mui/material';

import DialogTitle from 'components/UI/DialogTitle'
import DialogContent from 'components/UI/DialogContent'
import DialogActions from 'components/UI/DialogActions'
import Button from 'components/UI/Button'
import OutlinedInput from "components/UI/OutlinedInput";
import { ReactIf } from "components/common/ReactIf";
import { useCheckDBConnectionMutation } from 'services/admin'
import useIntlHelper from "hooks/useIntlHelper";
import { loader } from 'assets/icons'

import './styles.scss'

export type DatabaseInfoStepProps = {
  onClose: () => void
  btnType?: 'button' | 'submit' | 'reset'
  btnName: string
  btnCloseName: string
  register: any
  dialogClassName: string
  isEditMode: boolean
  watch: any
  manageProgress: boolean
}

const DatabaseInfoStep = ({
  onClose,
  btnType,
  btnName,
  btnCloseName,
  register,
  watch,
  manageProgress,
  isEditMode,
  dialogClassName,
}: DatabaseInfoStepProps) => {
  const { getIntlMessage } = useIntlHelper()
  const [isStepValid, setStepValid] = useState<boolean>(false)

  const db_name = watch('db_name')
  const db_username = watch('db_username')
  const db_password = watch('db_password')
  const db_host = watch('db_host')
  const db_port = watch('db_port')

  const [checkConnection, result]: any = useCheckDBConnectionMutation()

  const { isError, isSuccess, isLoading, data } = result

  const checkingTitle = useCallback(() => {
    switch (true) {
      case Boolean(isLoading):
        return 'checking'

      case Boolean(isError):
      case !Boolean(data):
        return 'connection_failed'

      case Boolean(isSuccess):
      case Boolean(data):
        return 'connected'

      default:
        return ''
    }
  }, [isLoading, isSuccess, isError, data])

  const handleCheckConnection = () => {
    const data = {
      db_name: watch('db_name'),
      db_username: watch('db_username'),
      db_password: watch('db_password'),
      db_host: watch('db_host'),
      db_port: watch('db_port'),
    }
    checkConnection(data)
  }

  useEffect(() => {
    setStepValid([db_name, db_username, db_password, db_host, db_port].every(v => Boolean(v)))
  },
    // eslint-disable-next-line
    [db_name, db_username, db_password, db_host, db_port, setStepValid])

  return (
    <div className={dialogClassName}>
      <DialogTitle>
        {getIntlMessage(isEditMode ? 'edit' : 'add')} database info
        <ReactIf value={!isEditMode}>
          <p>Step 2 of 2</p>
        </ReactIf>
      </DialogTitle>

      <DialogContent>
        <OutlinedInput label="databaseNameField" required register={register("db_name")} />

        <OutlinedInput label="usernameField" required register={register("db_username")} />

        <OutlinedInput
          showPasswordLogic
          hideIconWhenFieldIsEmpty
          watch={watch}
          defaultValue={""}
          label="authPassword" type="password" required register={register("db_password")}
        />

        <OutlinedInput
          label="hostField"
          required
          register={register("db_host")}
        />

        <OutlinedInput label="portField" required register={register("db_port")} type="number"
          onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
        />

        <div className='check-connection'>
          <p className='subtitle neutral-500'> Connection Results</p>

          <ReactIf value={[isLoading, isError, isSuccess].some(v => Boolean(v))}>
            <div className={clsx('checking-area', {
              ' bg-neutral-100': isLoading,
              ' bg-red': isError || !data,
              ' bg-success': isSuccess && data,
            })}>
              {getIntlMessage(checkingTitle())}

              {
                isLoading && <img alt='loader' src={loader} className='rotate-animation' />
              }
            </div>
          </ReactIf>

          <Button
            onClick={handleCheckConnection}
            disabled={isLoading}
            variant='outlined'
            fullWidth
          >
            {getIntlMessage("check_connection")}
          </Button>
        </div>

      </DialogContent>

      <DialogActions  >
        <Button onClick={onClose} variant='outlined'>
          {getIntlMessage(btnCloseName)}
        </Button>

        <Tooltip title={isStepValid ? "" : getIntlMessage("please_fill_all_mandatory_fields")} placement='top'>
          <div>
            <Button type={btnType} disabled={!isStepValid || manageProgress}>
              {getIntlMessage(btnName)}
            </Button>
          </div>
        </Tooltip>
      </DialogActions>
    </div>
  )
}

export default DatabaseInfoStep