import moment, { Moment } from 'moment';
import * as momentTimezone from 'moment-timezone';

const useRecentLogin = () => {
  const formatDate = (date: Moment) => {
    if (!date) {
      return ''
    }
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');

    if (date.isSame(today, 'day')) {
      return 'Today';
    } else if (date.isSame(yesterday, 'day')) {
      return 'Yesterday';
    } else {
      return date.format('MMM DD, YYYY');
    }
  }

  const convertUtcToLocalFormatted = (utcDateString: string, userTimezone: string) => {
    const utcMoment = momentTimezone.utc(utcDateString);
    const localMoment = utcMoment.tz(userTimezone);

    return formatDate(localMoment)
  }

  const formatTimeZone = (data: string) => {
    if (data) {
      const userTimezone = momentTimezone.tz.guess();

      return convertUtcToLocalFormatted(data, userTimezone);
    }
    return '';
  }

  return formatTimeZone
}

export default useRecentLogin