import {
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import { getValueFromLS } from 'helpers'
import { tokens } from 'invariants'

const baseApi = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,

  prepareHeaders: (headers: any, a: any) => {
    const token = getValueFromLS(tokens.accessToken)

    if (token && a.endpoint !== "getUserDataWithRefresh") {
      headers.set('Authorization', `Bearer ${token}`);
    }

    headers.set('CF-Access-Client-Id', process.env.REACT_APP_CF_ACCESS_CLIENT_ID);
    headers.set('CF-Access-Client-Secret', process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET);
    headers.set('Referrer-Policy', 'no-referrer');
    headers.set('Content-Security-Policy', `base-uri 'self'; block-all-mixed-content; font-src 'self' https: data:; object-src 'none'; script-src 'self'; script-src-attr 'none'; style-src 'self' https: 'unsafe-inline'; upgrade-insecure-requests; require-trusted-types-for 'script'`);

    return headers;
  }
});

export default baseApi